// ** Custom Components
import Icon from "components/global/Icon";

// ** SVG Static Imports
import { ReactComponent as SolidSpinner } from "resources/icons/solid/spinner.svg";

// #####################################################

const LoadingComponent = () => (
	<Icon svgIconComponent={SolidSpinner} spin pulse size="lg">
		spinner
	</Icon>
);

export default LoadingComponent;

// #####################################################

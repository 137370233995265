import defaults from "../../defaults";

import { vendor } from "store/vendor";

const GET_GROUP_STATUS = "up/groups/GET_GROUP_STATUS";
const GET_GROUP_STATUS_SUCCESS = "up/groups/GET_GROUP_STATUS_SUCCESS";
const GET_GROUP_STATUS_FAIL = "up/groups/GET_GROUP_STATUS_FAIL";

const GET_MY_GROUPS = "up/groups/userpanel/GET_MY_GROUPS";
const GET_MY_GROUPS_SUCCESS = "up/groups/userpanelGET_MY_GROUPS_SUCCESS";
const GET_MY_GROUPS_FAIL = "up/groups/userpanel/GET_MY_GROUPS_FAIL";

const REMOVE_MY_GROUP = "up/groups/REMOVE_MY_GROUP";
const REMOVE_MY_GROUP_SUCCESS = "up/groups/REMOVE_MY_GROUP_SUCCESS";
const REMOVE_MY_GROUP_FAIL = "up/groups/REMOVE_MY_GROUP_FAIL";

const GET_GROUP_INVITES = "up/groups/GET_GROUP_INVITES";
const GET_GROUP_INVITES_SUCCESS = "up/groups/GET_GROUP_INVITES_SUCCESS";
const GET_GROUP_INVITES_FAIL = "up/groups/GET_GROUP_INVITES_FAIL";

const REMOVE_GROUP_INVITE = "up/groups/REMOVE_GROUP_INVITE";
const REMOVE_GROUP_INVITE_SUCCESS = "up/groups/REMOVE_GROUP_INVITE_SUCCESS";
const REMOVE_GROUP_INVITE_FAIL = "up/groups/REMOVE_GROUP_INVITE_FAIL";

const GET_INVITATION_SEND = "up/groups/GET_INVITATION_SEND";
const GET_INVITATION_SEND_SUCCESS = "up/groups/GET_INVITATION_SEND_SUCCESS";
const GET_INVITATION_SEND_FAIL = "up/groups/GET_INVITATION_SEND_FAIL";

const REMOVE_INVITATION_SEND = "up/groups/REMOVE_INVITATION_SEND";
const REMOVE_INVITATION_SEND_SUCCESS =
	"up/groups/REMOVE_INVITATION_SEND_SUCCESS";
const REMOVE_INVITATION_SEND_FAIL = "up/groups/REMOVE_INVITATION_SEND_FAIL";

const GET_GROUP_MEMBER = "up/groups/GET_GROUP_MEMBER";
const GET_GROUP_MEMBER_SUCCESS = "up/groups/GET_GROUP_MEMBER_SUCCESS";
const GET_GROUP_MEMBER_FAIL = "up/groups/GET_GROUP_MEMBER_FAIL";

const REMOVE_FROM_GROUP = "up/groups/REMOVE_FROM_GROUP";
const REMOVE_FROM_GROUP_SUCCESS = "up/groups/REMOVE_FROM_GROUP_SUCCESS";
const REMOVE_FROM_GROUP_FAIL = "up/groups/REMOVE_FROM_GROUP_SUCCESS";

const GET_FRIENDS_SEND_INVITES = "up/groups/GET_FRIENDS_SEND_INVITES";
const GET_FRIENDS_SEND_INVITES_SUCCESS =
	"up/groups/GET_FRIENDS_SEND_INVITES_SUCCESS";
const GET_FRIENDS_SEND_INVITES_FAIL = "up/groups/GET_FRIENDS_SEND_INVITES_FAIL";

const GET_SEND_INVITES = "up/groups/GET_SEND_INVITES";
const GET_SEND_INVITES_SUCCESS = "up/groups/GET_SEND_INVITES_SUCCESS";
const GET_SEND_INVITES_FAIL = "up/groups/GET_SEND_INVITES_FAIL";

const SEND_INVITES = "up/groups/SEND_INVITES";
const SEND_INVITES_SUCCESS = "up/groups/SEND_INVITES_SUCCESS";
const SEND_INVITES_FAIL = "up/groups/SEND_INVITES_FAIL";

const DELETE_SEND_INVITES = "up/groups/DELETE_SEND_INVITES";
const DELETE_SEND_INVITES_SUCCESS = "up/groups/DELETE_SEND_INVITES_SUCCESS";
const DELETE_SEND_INVITES_FAIL = "up/groups/DELETE_SEND_INVITES_FAIL";

const GET_BLOCKED_USERS = "up/groups/GET_BLOCKED_USERS";
const GET_BLOCKED_USERS_SUCCESS = "up/groups/GET_BLOCKED_USERS_SUCCESS";
const GET_BLOCKED_USERS_FAIL = "up/groups/GET_BLOCKED_USERS_FAIL";

const DELETE_BLOCKED_USERS = "up/groups/DELETE_BLOCKED_USERS";
const DELETE_BLOCKED_USERS_SUCCESS = "up/groups/DELETE_BLOCKED_USERS_SUCCESS";
const DELETE_BLOCKED_USERS_FAIL = "up/groups/DELETE_BLOCKED_USERS_FAIL";

const GET_GROUP_ADMINS = "up/groups/GET_GROUP_ADMINS";
const GET_GROUP_ADMINS_SUCCESS = "up/groups/GET_GROUP_ADMINS_SUCCESS";
const GET_GROUP_ADMINS_FAIL = "up/groups/GET_GROUP_ADMINS_FAIL";

const DELETE_GROUP_ADMINS = "up/groups/DELETE_GROUP_ADMINS";
const DELETE_GROUP_ADMINS_SUCCESS = "up/groups/DELETE_GROUP_ADMINS_SUCCESS";
const DELETE_GROUP_ADMINS_FAIL = "up/groups/DELETE_GROUP_ADMINS_FAIL";

const GET_GROUP_APPLICATIONS = "up/groups/GET_GROUP_APPLICATIONS_ADMINS";
const GET_GROUP_APPLICATIONS_SUCCESS =
	"up/groups/GET_GROUP_APPLICATIONS_SUCCESS";
const GET_GROUP_APPLICATIONS_FAIL = "up/groups/GET_GROUP_APPLICATIONS_FAIL";

const DELETE_USER_FROM_APPLICATIONS =
	"up/groups/DELETE_USER_FROM_APPLICATIONS_ADMINS";
const DELETE_USER_FROM_APPLICATIONS_SUCCESS =
	"up/groups/DELETE_USER_FROM_APPLICATIONS_SUCCESS";
const DELETE_USER_FROM_APPLICATIONS_FAIL =
	"up/groups/DELETE_USER_FROM_APPLICATIONS_FAIL";

const GET_GROUP_MEMBERS = "up/groups/GET_GROUP_MEMBERS";
const GET_GROUP_MEMBERS_SUCCESS = "up/groups/GET_GROUP_MEMBERS_SUCCESS";
const GET_GROUP_MEMBERS_FAIL = "up/groups/GET_GROUP_MEMBERS_FAIL";

const DELETE_USER_FROM_MEMBERS = "up/groups/DELETE_USER_FROM_MEMBERS";
const DELETE_USER_FROM_MEMBERS_SUCCESS = "up/groups/DELETE_USER_FROM_MEMBERS";
const DELETE_USER_FROM_MEMBERS_FAIL = "up/groups/DELETE_USER_FROM_MEMBERS";

const BLOCK_USERS_IN_GROUP = "up/groups/BLOCK_USERS_IN_GROUP";
const BLOCK_USERS_IN_GROUP_SUCCESS = "up/groups/BLOCK_USERS_IN_GROUP_SUCCESS";
const BLOCK_USERS_IN_GROUP_FAIL = "up/groups/BLOCK_USERS_IN_GROUP_FAIL";

const SET_ADMIN_GROUP = "up/groups/SET_ADMIN_GROUP";
const SET_ADMIN_GROUP_SUCCESS = "up/groups/SET_ADMIN_GROUP_SUCCESS";
const SET_ADMIN_GROUP_FAIL = "up/groups/SET_ADMIN_GROUP_FAIL";

const BLOCK_USERS_IN_APPLICATIONS = "up/groups/BLOCK_USERS_IN_APPLICATIONS";
const BLOCK_USERS_IN_APPLICATIONS_SUCCESS =
	"up/groups/BLOCK_USERS_IN_APPLICATIONS_SUCCESS";
const BLOCK_USERS_IN_APPLICATIONS_FAIL =
	"up/groups/BLOCK_USERS_IN_APPLICATIONS_FAIL";

const ACCEPT_USERS_IN_APPLICATIONS = "up/groups/ACCEPT_USERS_IN_APPLICATIONS";
const ACCEPT_USERS_IN_APPLICATIONS_SUCCESS =
	"up/groups/ACCEPT_USERS_IN_APPLICATIONS_SUCCESS";
const ACCEPT_USERS_IN_APPLICATIONS_FAIL =
	"up/groups/ACCEPT_USERS_IN_APPLICATIONS_FAIL";

const GET_GROUP_POSTS = "up/groups/GET_GROUP_POSTS";
const GET_GROUP_POSTS_SUCCESS = "up/groups/GET_GROUP_POSTS_SUCCESS";
const GET_GROUP_POSTS_FAIL = "up/groups/GET_GROUP_POSTS_FAIL";

const DELETE_POST_FROM_GROUP = "up/groups/DELETE_POST_FROM_GROUP";
const DELETE_POST_FROM_GROUP_SUCCESS =
	"up/groups/DELETE_POST_FROM_GROUP_SUCCESS";
const DELETE_POST_FROM_GROUP_FAIL = "up/groups/DELETE_POST_FROM_GROUP_FAIL";

const DELETE_COMMENTS_FROM_GROUP_POST =
	"up/events/DELETE_COMMENTS_FROM_GROUP_POST";
const DELETE_COMMENTS_FROM_GROUP_POST_SUCCESS =
	"up/events/DELETE_COMMENTS_FROM_GROUP_POST_SUCCESS";
const DELETE_COMMENTS_FROM_GROUP_POST_FAIL =
	"up/events/DELETE_COMMENTS_FROM_GROUP_POST_FAIL";

const ACCEPT_INVITE_TO_GROUP = "up/groups/ACCEPT_INVITE_TO_GROUP";
const ACCEPT_INVITE_TO_GROUP_SUCCESS =
	"up/groups/ACCEPT_INVITE_TO_GROUP_SUCCESS";
const ACCEPT_INVITE_TO_GROUP_FAIL = "up/groups/ACCEPT_INVITE_TO_GROUP_FAIL";

const UPDATE_GROUP = "up/groups/UPDATE_GROUP";
const UPDATE_GROUP_SUCCESS = "up/groups/UPDATE_GROUP_SUCCESS";
const UPDATE_GROUP_FAIL = "up/groups/UPDATE_GROUP_FAIL";

const GET_GROUP_TO_EDIT = "up/groups/GET_GROUP_TO_EDIT";
const GET_GROUP_TO_EDIT_SUCCESS = "up/groups/GET_GROUP_TO_EDIT_SUCCESS";
const GET_GROUP_TO_EDIT_FAIL = "up/groups/GET_GROUP_TO_EDIT_FAIL";

const GROUP_AVATAR_UPLOAD_PROGRESS = "up/groups/GROUP_AVATAR_UPLOAD_PROGRESS";

const initialState = defaults.userpanel.groups;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_GROUP_STATUS:
			if (action.groupId === String(state.groupStatus.id)) {
				return {
					...state,
					groupStatus: {
						...state.groupStatus,
						loading: true,
					},
				};
			}
			return {
				...state,
				groupStatus: {
					...initialState.groupStatus,
					loading: true,
				},
			};
		case GET_GROUP_STATUS_SUCCESS:
			return {
				...state,
				groupStatus: {
					...state.groupStatus,
					loading: false,
					loaded: true,
					id: action.result.data.id,
					name: action.result.data.name,
					status: action.result.data.your_status,
				},
			};
		case GET_GROUP_STATUS_FAIL:
			return {
				...state,
				groupStatus: {
					...initialState.groupStatus,
					loading: false,
					loaded: true,
				},
			};

		case GET_MY_GROUPS:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					loading: true,
				},
			};
		case GET_MY_GROUPS_SUCCESS:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					loading: false,
					loaded: true,
					data: action.result.data?.groups ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_MY_GROUPS_FAIL:
			return {
				...state,
				myGroups: {
					...initialState.myGroups,
					loading: false,
					loaded: true,
				},
			};
		case REMOVE_MY_GROUP:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					whileRemoving: true,
				},
			};
		case REMOVE_MY_GROUP_SUCCESS:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					whileRemoving: false,
				},
			};
		case REMOVE_MY_GROUP_FAIL:
			return {
				...state,
				myGroups: {
					...state.myGroups,
					whileRemoving: false,
				},
			};
		case GET_GROUP_INVITES:
			return {
				...state,
				invite: {
					...state.invite,
					loading: true,
				},
			};
		case GET_GROUP_INVITES_SUCCESS:
			return {
				...state,
				invite: {
					...state.invite,
					loading: false,
					loaded: true,
					groups: action.result.data?.groups ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_INVITES_FAIL:
			return {
				...state,
				invite: {
					...initialState.invite,
					loading: false,
				},
			};
		case REMOVE_GROUP_INVITE:
			return {
				...state,
				invite: {
					...state.invite,
					whileRemoving: true,
				},
			};
		case REMOVE_GROUP_INVITE_SUCCESS:
			return {
				...state,
				invite: {
					...state.invite,
					whileRemoving: false,
					groups: state.invite.groups.filter(
						(group) => !action.data.includes(group.id)
					),
				},
			};
		case REMOVE_GROUP_INVITE_FAIL:
			return {
				...state,
				invite: {
					...initialState.invite,
					whileRemoving: false,
				},
			};
		case GET_INVITATION_SEND:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: true,
				},
			};
		case GET_INVITATION_SEND_SUCCESS:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: false,
					loaded: true,
					groups: action.result.data?.groups ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_INVITATION_SEND_FAIL:
			return {
				...state,
				invitationsSend: {
					...initialState.invitationsSend,
					loaded: true,
					loading: false,
				},
			};
		case REMOVE_INVITATION_SEND:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					whileRemoving: true,
				},
			};
		case REMOVE_INVITATION_SEND_SUCCESS:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					whileRemoving: false,
					groups: state.invitationsSend.groups.filter(
						(group) => !action.data.includes(group.id)
					),
				},
			};
		case REMOVE_INVITATION_SEND_FAIL:
			return {
				...state,
				invitationsSend: {
					...initialState.invitationsSend,
					whileRemoving: false,
				},
			};
		case GET_GROUP_MEMBER:
			return {
				...state,
				member: {
					...state.member,
					loading: true,
				},
			};
		case GET_GROUP_MEMBER_SUCCESS:
			return {
				...state,
				member: {
					...state.member,
					loading: false,
					loaded: true,
					groups: action.result.data?.groups ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_MEMBER_FAIL:
			return {
				...state,
				member: {
					...initialState.member,
					loading: false,
				},
			};
		case REMOVE_FROM_GROUP:
			return {
				...state,
				member: {
					...state.member,
					whileRemoving: true,
				},
			};
		case REMOVE_FROM_GROUP_SUCCESS:
			return {
				...state,
				member: {
					...state.member,
					whileRemoving: false,
					groups: state.member.groups.filter(
						(group) => !(action?.data || []).includes(group.id)
					),
				},
			};
		case REMOVE_FROM_GROUP_FAIL:
			return {
				...state,
				member: {
					...initialState.member,
					whileRemoving: false,
				},
			};
		case GET_FRIENDS_SEND_INVITES:
			if (action.groupId === state.friendsSendInvites.groupId)
				return {
					...state,
					friendsSendInvites: {
						...state.friendsSendInvites,
						loading: true,
					},
				};
			return {
				...state,
				friendsSendInvites: {
					...initialState.friendsSendInvites,
					loading: true,
				},
			};
		case GET_FRIENDS_SEND_INVITES_SUCCESS:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_FRIENDS_SEND_INVITES_FAIL:
			return {
				...state,
				friendsSendInvites: {
					...initialState.friendsSendInvites,
					loading: false,
					loaded: true,
				},
			};
		case SEND_INVITES:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: true,
				},
			};
		case SEND_INVITES_SUCCESS:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: false,
				},
			};
		case SEND_INVITES_FAIL:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: false,
				},
			};
		case GET_SEND_INVITES:
			if (action.groupId === state.sendInvites.groupId)
				return {
					...state,
					sendInvites: {
						...state.sendInvites,
						loading: true,
					},
				};

			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					loading: true,
				},
			};
		case GET_SEND_INVITES_SUCCESS:
			return {
				...state,
				sendInvites: {
					...state.sendInvites,
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_SEND_INVITES_FAIL:
			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_SEND_INVITES:
			return {
				...state,
				sendInvites: {
					...state.sendInvites,
					whileRemoving: true,
				},
			};
		case DELETE_SEND_INVITES_SUCCESS:
			return {
				...state,
				sendInvites: {
					...state.sendInvites,
					whileRemoving: false,
				},
			};
		case DELETE_SEND_INVITES_FAIL:
			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					whileRemoving: false,
				},
			};
		case GET_GROUP_TO_EDIT:
			if (action.groupId === String(state.groupEdit.groupId))
				return {
					...state,
					groupEdit: {
						...state.groupEdit,
						loading: true,
					},
				};
			return {
				...state,
				groupEdit: {
					...initialState.groupEdit,
					loading: true,
				},
			};
		case GET_GROUP_TO_EDIT_SUCCESS:
			return {
				...state,
				groupEdit: {
					loading: false,
					loaded: true,
					groupId: action.result.data.id,
					name: action.result.data.name,
					avatar: action.result.data.avatar,
					description: action.result.data.description,
					descriptionRaw: action.result.data.description_raw,
					auto_add: action.result.data.auto_add,
					created_date: action.result.data.created_date,
					accepted: {
						profile: action.result.data.accepted.profile,
						description: action.result.data.accepted.description,
						avatar: action.result.data.accepted.avatar,
					},
					sex_post: {
						woman: action.result.data.sex_post.woman,
						man: action.result.data.sex_post.man,
						couple: action.result.data.sex_post.couple,
						transsexual: action.result.data.sex_post.transsexual,
					},
					sex_invite: {
						woman: action.result.data.sex_invite.woman,
						man: action.result.data.sex_invite.man,
						couple: action.result.data.sex_invite.couple,
						transsexual: action.result.data.sex_invite.transsexual,
					},
				},
			};
		case GET_GROUP_TO_EDIT_FAIL:
			return {
				...state,
				groupEdit: {
					...initialState.groupEdit,
					loading: false,
					loaded: true,
				},
			};
		case GET_GROUP_MEMBERS:
			if (action.groupId === state.groupMembers.groupId) {
				return {
					...state,
					groupMembers: {
						...state.groupMembers,
						loading: true,
					},
				};
			}
			return {
				...state,
				groupMembers: {
					...initialState.groupMembers,
					loading: true,
				},
			};
		case GET_GROUP_MEMBERS_SUCCESS:
			return {
				...state,
				groupMembers: {
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_MEMBERS_FAIL:
			return {
				...state,
				groupMembers: {
					...initialState.groupMembers,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_USER_FROM_MEMBERS:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileRemoving: true,
				},
			};
		case DELETE_USER_FROM_MEMBERS_SUCCESS:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileRemoving: false,
				},
			};
		case DELETE_USER_FROM_MEMBERS_FAIL:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileRemoving: false,
				},
			};
		case BLOCK_USERS_IN_GROUP:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileBlocking: true,
				},
			};
		case BLOCK_USERS_IN_GROUP_SUCCESS:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileBlocking: false,
				},
			};
		case BLOCK_USERS_IN_GROUP_FAIL:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileBlocking: false,
				},
			};
		case SET_ADMIN_GROUP:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileAdding: true,
				},
			};
		case SET_ADMIN_GROUP_SUCCESS:
			return {
				...state,
				groupMembers: {
					...state.groupMembers,
					whileAdding: false,
				},
			};
		case SET_ADMIN_GROUP_FAIL:
			return {
				...state,
				groupMembers: {
					...initialState.groupMembers,
					whileAdding: false,
				},
			};
		case GET_GROUP_ADMINS:
			if (action.groupId === state.groupAdmins.groupId) {
				return {
					...state,
					groupAdmins: {
						...state.groupAdmins,
						loading: true,
						groupId: action.groupId,
					},
				};
			}
			return {
				...state,
				groupAdmins: {
					...initialState.groupAdmins,
					loading: true,
				},
			};
		case GET_GROUP_ADMINS_SUCCESS:
			return {
				...state,
				groupAdmins: {
					...state.groupAdmins,
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result?.data.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_ADMINS_FAIL:
			return {
				...state,
				groupAdmins: {
					...initialState.groupAdmins,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_GROUP_ADMINS:
			return {
				...state,
				groupAdmins: {
					...state.groupAdmins,
					whileRemoving: true,
				},
			};
		case DELETE_GROUP_ADMINS_SUCCESS:
			return {
				...state,
				groupAdmins: {
					...state.groupAdmins,
					whileRemoving: false,
				},
			};
		case DELETE_GROUP_ADMINS_FAIL:
			return {
				...state,
				groupAdmins: {
					...state.groupAdmins,
					whileRemoving: false,
				},
			};
		case GET_GROUP_APPLICATIONS:
			if (action.groupId === state.groupApplications.groupId)
				return {
					...state,
					groupApplications: {
						...state.groupApplications,
						loading: true,
					},
				};
			return {
				...state,
				groupApplications: {
					...initialState.groupApplications,
					loading: true,
				},
			};
		case GET_GROUP_APPLICATIONS_SUCCESS:
			return {
				...state,
				groupApplications: {
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_APPLICATIONS_FAIL:
			return {
				...state,
				groupApplications: {
					...initialState.groupApplications,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS:
			return {
				...state,
				groupApplications: {
					...state.groupApplications,
					whileRemoving: true,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS_SUCCESS:
			return {
				...state,
				groupApplications: {
					...state.groupApplications,
					whileRemoving: false,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS_FAIL:
			return {
				...state,
				groupApplications: {
					...state.groupApplications,
					whileRemoving: false,
				},
			};
		case GET_BLOCKED_USERS:
			if (action.groupId === state.blockedUsers.groupId)
				return {
					...state,
					blockedUsers: {
						...state.blockedUsers,
						loading: true,
					},
				};
			return {
				...state,
				blockedUsers: {
					...initialState.blockedUsers,
					loading: true,
				},
			};
		case GET_BLOCKED_USERS_SUCCESS:
			return {
				...state,
				blockedUsers: {
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_BLOCKED_USERS_FAIL:
			return {
				...state,
				blockedUsers: {
					...initialState.blockedUsers,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_BLOCKED_USERS:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: true,
				},
			};
		case DELETE_BLOCKED_USERS_SUCCESS:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: false,
				},
			};
		case DELETE_BLOCKED_USERS_FAIL:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: false,
				},
			};
		case GET_GROUP_POSTS:
			if (action.groupId === state.groupPosts.groupId)
				return {
					...state,
					groupPosts: {
						...state.groupPosts,
						loading: true,
					},
				};
			return {
				...state,
				groupPosts: {
					...initialState.groupPosts,
					loading: true,
				},
			};
		case GET_GROUP_POSTS_SUCCESS:
			return {
				...state,
				groupPosts: {
					loading: false,
					loaded: true,
					groupId: action.groupId,
					data: action.result.data?.posts ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_GROUP_POSTS_FAIL:
			return {
				...state,
				groupPosts: {
					...initialState.groupPosts,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_POST_FROM_GROUP:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: true,
				},
			};
		case DELETE_POST_FROM_GROUP_SUCCESS:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: false,
				},
			};
		case DELETE_POST_FROM_GROUP_FAIL:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: false,
				},
			};
		case DELETE_COMMENTS_FROM_GROUP_POST:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: true,
				},
			};
		case DELETE_COMMENTS_FROM_GROUP_POST_SUCCESS:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: false,
				},
			};
		case DELETE_COMMENTS_FROM_GROUP_POST_FAIL:
			return {
				...state,
				groupPosts: {
					...state.groupPosts,
					whileRemoving: false,
				},
			};
		case UPDATE_GROUP:
			return {
				...state,
				avatarUploadProgress: 0,
			};
		case GROUP_AVATAR_UPLOAD_PROGRESS:
			return {
				...state,
				avatarUploadProgress: action.avatarUploadProgress,
			};
		default:
			return state;
	}
}

/**
 * Pobiera informacje o grupie.
 * @api GET getGroupById
 * @param {Number} id identyfikator grupy
 */

export function getGroupStatus(groupId) {
	return {
		types: [
			GET_GROUP_STATUS,
			GET_GROUP_STATUS_SUCCESS,
			GET_GROUP_STATUS_FAIL,
		],
		promise: (client) =>
			client.get(`/group`, {
				params: {
					id: groupId,
				},
			}),
		groupId,
	};
}

/**
 * Zwraca listę aktualnych grup
 * @api GET getGroupsMy
 * @param {Number} page numer strony
 */
export function getMyGroups(page) {
	return {
		types: [GET_MY_GROUPS, GET_MY_GROUPS_SUCCESS, GET_MY_GROUPS_FAIL],
		promise: (client) =>
			client.get(`/group/admin`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa grupe z listy grup.
 * @api DELETE deleteGroupMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów grup
 */
export function removeGroups(ids) {
	return {
		types: [REMOVE_MY_GROUP, REMOVE_MY_GROUP_SUCCESS, REMOVE_MY_GROUP_FAIL],
		promise: (client) =>
			client.delete(`/group/leave/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Zwraca listę aktualnych zaproszeń do grup
 * @api GET getGroupsInvite
 * @param {Number} page numer strony
 */
export function getInviteGroup(page) {
	return {
		types: [
			GET_GROUP_INVITES,
			GET_GROUP_INVITES_SUCCESS,
			GET_GROUP_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/group/invite`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa grupe z listy grup zaproszeń.
 * @api DELETE deleteGroupsInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów grup
 */
export function removeInvites(ids) {
	return {
		types: [
			REMOVE_GROUP_INVITE,
			REMOVE_GROUP_INVITE_SUCCESS,
			REMOVE_GROUP_INVITE_FAIL,
		],
		promise: (client) =>
			client.delete(`/group/invite/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę aktualnych grup, do których zostało wysłana prośba o dołączenie.
 * @api GET getGroupsSendInvite
 * @param {Number} page numer strony
 */
export function getInvitationSend(page) {
	return {
		types: [
			GET_INVITATION_SEND,
			GET_INVITATION_SEND_SUCCESS,
			GET_INVITATION_SEND_FAIL,
		],
		promise: (client) =>
			client.get(`/group/send-invite`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa grupe z listy wysłanych zaproszeń do grup.
 * @api DELETE deleteGroupsSendInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów grup
 */
export function removeInvitationSend(ids) {
	return {
		types: [
			REMOVE_INVITATION_SEND,
			REMOVE_INVITATION_SEND_SUCCESS,
			REMOVE_INVITATION_SEND_FAIL,
		],
		promise: (client) =>
			client.delete(`/group/send-invite/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę aktualnych grup, do których należy user.
 * @api GET getGroupsSendInvite
 * @param {Number} page numer strony
 */
export function getGroupMember(page) {
	return {
		types: [
			GET_GROUP_MEMBER,
			GET_GROUP_MEMBER_SUCCESS,
			GET_GROUP_MEMBER_FAIL,
		],
		promise: (client) =>
			client.get(`/group/belong`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa użytkownika z listy użytkowników w danej grupie.
 * @api DELETE deleteGroupsSendInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów grup
 */
export function removeFromGroup(ids) {
	return {
		types: [
			REMOVE_FROM_GROUP,
			REMOVE_FROM_GROUP_SUCCESS,
			REMOVE_FROM_GROUP_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/leave/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste uzytkowników którem możemy wysłać zaproszenie do grupy.
 * @api GET getFriendsSendInvites
 * @param {Number} groupId identyfikator grupy
 * @param {Number} pageIndex numer strony
 */
export function getFriendsSendInvites(groupId, pageIndex) {
	return {
		types: [
			GET_FRIENDS_SEND_INVITES,
			GET_FRIENDS_SEND_INVITES_SUCCESS,
			GET_FRIENDS_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user/invite-send/friends`, {
				params: {
					id: groupId,
					page: pageIndex,
				},
			}),
		groupId,
	};
}

/**
 * Pobiera liste uzytkowników których zaprosilismy do danej do grupy.
 * @api GET getSendInvites
 * @param {Number} groupId identyfikator grupy
 * @param {Number} pageIndex numer strony
 */
export function getSendInvites(groupId, pageIndex) {
	return {
		types: [
			GET_SEND_INVITES,
			GET_SEND_INVITES_SUCCESS,
			GET_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user/invite-send`, {
				params: {
					id: groupId,
					page: pageIndex,
				},
			}),
		groupId,
	};
}

/**
 * Dodaje użytkowników do listy zaproszonych do danej grupy
 * @api POST inviteToGroupMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function inviteToGroup(groupId, ids) {
	return {
		types: [SEND_INVITES, SEND_INVITES_SUCCESS, SEND_INVITES_FAIL],
		promise: (client) =>
			client.post(
				`/group-user/invite-send/multiple?id=${groupId}&ids=${ids.join(
					","
				)}`
			),
	};
}

/**
 * Usuwa użytkowników z listy zaproszonych do danej grupy
 * @api DELETE deleteInviteFromGroupMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteInviteToGroup(groupId, ids) {
	return {
		types: [
			DELETE_SEND_INVITES,
			DELETE_SEND_INVITES_SUCCESS,
			DELETE_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/invite-send/multiple`, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste zablokowanych uzytkowników w danej grupie.
 * @api GET getBlockUsers
 * @param {Number} groupId identyfikator grupy
 * @param {Number} pageIndex numer strony
 */
export function getBlockUsers(groupId, pageIndex) {
	return {
		types: [
			GET_BLOCKED_USERS,
			GET_BLOCKED_USERS_SUCCESS,
			GET_BLOCKED_USERS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user/block`, {
				params: {
					id: groupId,
					page: pageIndex,
				},
			}),
		groupId,
	};
}

/**
 * Usuwa użytkowników z listy zbanowanych danej grupy
 * @api DELETE deleteBlockUserGroupMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteBlockUserGroup(groupId, ids) {
	return {
		types: [
			DELETE_BLOCKED_USERS,
			DELETE_BLOCKED_USERS_SUCCESS,
			DELETE_BLOCKED_USERS_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/block/multiple`, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste właścicieli grupy.
 * @api GET getGroupAdminsById
 * @param {Number} groupId identyfikator grupy
 */
export function getGroupAdmins(groupId, page) {
	return {
		types: [
			GET_GROUP_ADMINS,
			GET_GROUP_ADMINS_SUCCESS,
			GET_GROUP_ADMINS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user/admins/panel`, {
				params: {
					id: groupId,
					page,
				},
			}),
		groupId,
	};
}

/**
 * Usuwa użytkowników z listy właścicieli danej grupy
 * @api DELETE deleteAdminFromGroupMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteAdminFromGroup(groupId, ids) {
	return {
		types: [
			DELETE_GROUP_ADMINS,
			DELETE_GROUP_ADMINS_SUCCESS,
			DELETE_GROUP_ADMINS_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/admins/multiple`, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste zgłoszeń do grupy.
 * @api GET getUsersSendInvites
 * @param {Number} groupId identyfikator grupy
 * @param {Number} pageIndex numer strony
 */
export function getUsersSendInvites(groupId, pageIndex) {
	return {
		types: [
			GET_GROUP_APPLICATIONS,
			GET_GROUP_APPLICATIONS_SUCCESS,
			GET_GROUP_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user/invite-user`, {
				params: {
					id: groupId,
					page: pageIndex,
				},
			}),
		groupId,
	};
}

/**
 * Usuwa użytkownika z listy do zatwierdzenia w danej grupie.
 * @api DELETE deleteGroupsSendInviteMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function removeUserFromApplications(groupId, ids) {
	return {
		types: [
			DELETE_USER_FROM_APPLICATIONS,
			DELETE_USER_FROM_APPLICATIONS_SUCCESS,
			DELETE_USER_FROM_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/multiple`, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste userow w grupie.
 * @api GET getGroupUsersById
 * @param {Number} groupId identyfikator grupy
 * @param {Number} pageIndex numer strony
 */
export function getGroupUsers(groupId, pageIndex) {
	return {
		types: [
			GET_GROUP_MEMBERS,
			GET_GROUP_MEMBERS_SUCCESS,
			GET_GROUP_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.get(`/group-user`, {
				params: {
					id: groupId,
					page: pageIndex,
				},
			}),
		groupId,
	};
}

/**
 * Usuwa użytkownika z listy użytkowników w danej grupie.
 * @api DELETE deleteGroupsSendInviteMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function removeUserFromMembers(groupId, ids) {
	return {
		types: [
			DELETE_USER_FROM_MEMBERS,
			DELETE_USER_FROM_MEMBERS_SUCCESS,
			DELETE_USER_FROM_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-user/multiple`, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Blokuje użytkownika w danej grupie.
 * @api POST blockUserGroupMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function blockUsersInGroup(groupId, ids) {
	return {
		types: [
			BLOCK_USERS_IN_GROUP,
			BLOCK_USERS_IN_GROUP_SUCCESS,
			BLOCK_USERS_IN_GROUP_FAIL,
		],
		promise: (client) =>
			client.post(
				`/group-user/block/multiple?id=${groupId}&ids=${ids.join(",")}`
			),
	};
}

/**
 * Dodaje użytkownika do właścicieli grupy.
 * @api PUT setAdminGroupMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function setAdminGroup(groupId, ids) {
	return {
		types: [SET_ADMIN_GROUP, SET_ADMIN_GROUP_SUCCESS, SET_ADMIN_GROUP_FAIL],
		promise: (client) =>
			client.put(`/group-user/admins/multiple`, null, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Blokuje użytkownika w zgłoszeniach do danej grupy.
 * @api POST blockUserGroupMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function blockUsersInApplications(groupId, ids) {
	return {
		types: [
			BLOCK_USERS_IN_APPLICATIONS,
			BLOCK_USERS_IN_APPLICATIONS_SUCCESS,
			BLOCK_USERS_IN_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.post(`/group-user/block/multiple`, null, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Przyjmuje użytkownika w zgłoszeniach do danej grupy.
 * @api PUT acceptUserToGroupMultiple
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function acceptUsersInApplications(groupId, ids) {
	return {
		types: [
			ACCEPT_USERS_IN_APPLICATIONS,
			ACCEPT_USERS_IN_APPLICATIONS_SUCCESS,
			ACCEPT_USERS_IN_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.put(`/group-user/multiple`, null, {
				params: {
					id: groupId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera posty z danej grupy.
 * @api GET getGroupPosts
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} pageIndex index tablicy
 */
export function getGroupPosts(groupId, pageIndex, comment = false) {
	return {
		types: [GET_GROUP_POSTS, GET_GROUP_POSTS_SUCCESS, GET_GROUP_POSTS_FAIL],
		promise: (client) =>
			client.get(`/group-content`, {
				params: {
					id: groupId,
					page: pageIndex,
					comment,
					commentNoLimit: true,
				},
			}),
		groupId,
	};
}

/**
 * Usuwa posty w danej grupie.
 * @api DELETE deleteGroupPost
 * @param {Number} groupId identyfikator grupy
 * @param {Array.<Number>} ids tablica identyfikatorów postów
 */
export function deleteGroupPost(groupId, id) {
	return {
		types: [
			DELETE_POST_FROM_GROUP,
			DELETE_POST_FROM_GROUP_SUCCESS,
			DELETE_POST_FROM_GROUP_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-content`, {
				params: {
					id: groupId,
					postId: id,
				},
			}),
	};
}

/**
 * Usuwa komentarz do danego posta w grupie.
 * @api DELETE deleteGroupPostComment
 * @param {Number} groupId identyfikator grupy
 * @param {Number} commentId identyfikator komentarz
 */
export function deleteGroupPostComment(groupId, commentId) {
	return {
		types: [
			DELETE_COMMENTS_FROM_GROUP_POST,
			DELETE_COMMENTS_FROM_GROUP_POST_SUCCESS,
			DELETE_COMMENTS_FROM_GROUP_POST_FAIL,
		],
		promise: (client) =>
			client.delete(`/group-content/comment`, {
				params: {
					id: groupId,
					commentId,
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

/**
 * Akceptuje zaproszenia do grup;
 * @api POST acceptGroupsInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów grup
 */
export function acceptGroupsInvite(ids) {
	return {
		types: [
			ACCEPT_INVITE_TO_GROUP,
			ACCEPT_INVITE_TO_GROUP_SUCCESS,
			ACCEPT_INVITE_TO_GROUP_FAIL,
		],
		promise: (client) =>
			client.post(`/group/invite/multiple?ids=${ids.join(",")}`),
	};
}

/**
 * Edytuje informacje o grupie.
 * @api Post updateGroup
 * @param {Number} data nowe dane
 */
export function updateGroup(data, avatar, token) {
	const formData = new FormData();

	formData.append("data", JSON.stringify(data));

	if (avatar) {
		formData.append("avatar", avatar);
	}

	formData.append("token", token);

	return {
		types: [UPDATE_GROUP, UPDATE_GROUP_SUCCESS, UPDATE_GROUP_FAIL],
		promise: (client) =>
			client.post(`/group/edit`, formData, {
				onUploadProgress: (e) => {
					vendor.store.dispatch(
						setGroupAvatarUploadProgress(
							Math.round((e.loaded * 100) / e.total)
						)
					);
				},
			}),
	};
}
/**
 * Pobiera informacje o grupie.
 * @api GET getGroupById
 * @param {Number} groupId identyfikator grupy
 */
export function getGroupToEdit(groupId) {
	return {
		types: [
			GET_GROUP_TO_EDIT,
			GET_GROUP_TO_EDIT_SUCCESS,
			GET_GROUP_TO_EDIT_FAIL,
		],
		promise: (client) =>
			client.get(`/group/edit`, {
				params: {
					id: groupId,
				},
			}),
		groupId,
	};
}

export function setGroupAvatarUploadProgress(progress) {
	return {
		type: GROUP_AVATAR_UPLOAD_PROGRESS,
		avatarUploadProgress: progress,
	};
}

import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonStyled = styled("button").attrs(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ min, submit, reset, label, disabled, ...props }) => ({
		// eslint-disable-next-line no-nested-ternary
		type: submit ? "submit" : reset ? "reset" : "button",
		disabled,
		role: "button",
		"aria-label": label,
		"aria-disabled": Boolean(disabled).toString(),
		...props,
	})
)`
	font-family: Arial, Helvetica, sans-serif;
	color: var(--dz-sys-button-secondary-text-color);

	&:disabled {
		opacity: var(--dz-sys-button-disabled-opacity);
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = ({ min, innerRef, ...props }) => (
	<ButtonStyled {...props} ref={innerRef} />
);

Button.propTypes = {
	submit: PropTypes.bool,
	reset: PropTypes.bool,
	disabled: PropTypes.bool,
};

Button.displayName = "Button";

export default Button;

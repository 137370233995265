// ** Third Party Components
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";

// ** Default State
import defaults from "../defaults";

// ** Config
import config from "../../configuration";
import { processFakeUserData } from "utils/processFakeUserData";

// #####################################################

const LOGIN = "auth/LOGIN";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "auth/LOGIN_FAIL";

const LOGOUT = "auth/LOGOUT";

const REGISTER = "auth/REGISTER";
const REGISTER_SUCCESS = "auth/REGISTER_SUCCESS";
const REGISTER_FAIL = "auth/REGISTER_FAIL";

const REGISTER_VERIFY = "auth/REGISTER_VERIFY";
const REGISTER_VERIFY_SUCCESS = "auth/REGISTER_VERIFY_SUCCESS";
const REGISTER_VERIFY_FAIL = "auth/REGISTER_VERIFY_FAIL";

const REGISTER_VERIFY_SMS_CODE = "auth/REGISTER_VERIFY_SMS_CODE";
const REGISTER_VERIFY_SMS_CODE_SUCCESS =
	"auth/REGISTER_VERIFY_SMS_CODE_SUCCESS";
const REGISTER_VERIFY_SMS_CODE_FAIL = "auth/REGISTER_VERIFY_SMS_CODE_FAIL";

const SET_FAKE_USER_DATA = "auth/SET_FAKE_USER_DATA";
const SET_SENT_SMS_CODE_TIME = "auty/SET_SENT_SMS_CODE_TIME";

const RECOVER = "auth/RECOVER";
const RECOVER_SUCCESS = "auth/RECOVER_SUCCESS";
const RECOVER_FAIL = "auth/RECOVER_FAIL";

const SET_API_TOKEN = "auth/SET_API_TOKEN";

const SET_TERMS = "auth/SET_TERMS";

const SET_GENDER = "auth/SET_GENDER";
const SET_GENDER_COUPLE = "auth/SET_GENDER_COUPLE";
const SET_GENDER_COUPLE_PARTNER = "auth/SET_GENDER_COUPLE_PARTNER";

const SET_AGE = "auth/SET_AGE";
const SET_AGE_COUPLE = "auth/SET_AGE_COUPLE";
const SET_AGE_COUPLE_PARTNER = "auth/SET_AGE_COUPLE_PARTNER";

const SET_LOGIN = "auth/SET_LOGIN";

const CHECK_LOGIN_AVAILABLE = "auth/CHECK_LOGIN_AVAILABLE";
const CHECK_LOGIN_AVAILABLE_SUCCESS = "auth/CHECK_LOGIN_AVAILABLE_SUCCESS";
const CHECK_LOGIN_AVAILABLE_FAIL = "auth/CHECK_LOGIN_AVAILABLE_FAIL";

const SET_PASSWORD = "auth/SET_PASSWORD";

const SET_EMAIL = "auth/SET_EMAIL";

const CHECK_EMAIL_AVAILABLE = "auth/CHECK_EMAIL_AVAILABLE";
const CHECK_EMAIL_AVAILABLE_SUCCESS = "auth/CHECK_EMAIL_AVAILABLE_SUCCESS";
const CHECK_EMAIL_AVAILABLE_FAIL = "auth/CHECK_EMAIL_AVAILABLE_FAIL";

const SET_LOCATION = "auth/SET_LOCATION";

const CLEAR_REGISTER_FORM = "auth/CLEAR_REGISTER_FORM";

// #####################################################

const initialState = defaults.auth;

// #####################################################

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case REGISTER_VERIFY:
		case REGISTER_VERIFY_SMS_CODE:
		case LOGIN: {
			cookie.remove("logged_out", { path: "/" });
			return {
				...state,
				login: {
					...initialState.login,
					loading: true,
				},
			};
		}

		case REGISTER_VERIFY_SUCCESS:
		case REGISTER_VERIFY_SMS_CODE_SUCCESS:
		case LOGIN_SUCCESS: {
			cookie.remove("logged_out", { path: "/" });
			const { headers } = action.result;
			let token = null;
			if (headers.authorization) {
				// eslint-disable-next-line prefer-destructuring
				token = headers.authorization.match(/Bearer\s(\S+)/)[1];
				cookie.set(
					config.auth.cookieName,
					token,
					config.auth.cookieOptionsJsCookie
				);
			}

			return {
				...state,
				token,
				jwt: jwtDecode(token),
				login: { ...state.login, loading: false, loaded: true },
				isAuthFromServer: true,
			};
		}
		case REGISTER_VERIFY_FAIL:
		case REGISTER_VERIFY_SMS_CODE_FAIL:
		case LOGIN_FAIL: {
			cookie.remove("logged_out", { path: "/" });
			return {
				...state,
				login: {
					...state.login,
					loading: false,
					loaded: true,
					error: true,
					errorMessage: action.error,
				},
			};
		}

		case LOGOUT: {
			// case DELETE_SUCCESS:
			cookie.remove(config.auth.cookieName, { path: "/" });
			cookie.set("logged_out", 1, {
				path: "/",
				expires: 1,
			});

			return {
				...state,
				jwt: null,
				token: false,
			};
		}
		case REGISTER:
			return {
				...state,
				register: {
					...state.register,
					loading: true,
					loaded: false,
					error: false,
				},
			};
		case REGISTER_SUCCESS:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					loaded: true,
				},
			};
		case REGISTER_FAIL:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		case RECOVER:
			return {
				...state,
				recover: {
					...state.recover,
				},
			};
		case RECOVER_SUCCESS:
			return {
				...state,
				recover: {
					...state.recover,
				},
			};
		case RECOVER_FAIL:
			return {
				...state,
				recover: {
					...state.recover,
				},
			};
		case SET_API_TOKEN:
			return {
				...state,
				token: action.token,
				jwt: action.token ? jwtDecode(action.token) : null,
			};
		// ** SET TERMS **
		case SET_TERMS:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						terms: action.isChecked,
					},
				},
			};
		// ** SET GENDER **
		case SET_GENDER:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						gender: {
							coupleCase: action.selectedGender === "couple",
							gender: action.selectedGender,
						},
					},
				},
			};
		// ** SET GENDER COUPLE **
		case SET_GENDER_COUPLE:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						genderCoupleCase: {
							...state.register.registerForm.genderCoupleCase,
							partner1: action.selectedGender,
						},
					},
				},
			};
		// ** SET GENDER COUPLE PARTNER **
		case SET_GENDER_COUPLE_PARTNER:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						genderCoupleCase: {
							...state.register.registerForm.genderCoupleCase,
							partner2: action.selectedGender,
						},
					},
				},
			};
		// ** SET AGE **
		case SET_AGE:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						age: {
							day:
								action.selectedAge.day === null
									? null
									: action.selectedAge.day ||
									  state.register.registerForm.age.day,
							month:
								action.selectedAge.month === null
									? null
									: action.selectedAge.month ||
									  state.register.registerForm.age.month,
							year:
								action.selectedAge.year === null
									? null
									: action.selectedAge.year ||
									  state.register.registerForm.age.year,
							isSelected: Boolean(
								typeof (
									action.selectedAge.day ||
									state.register.registerForm.age.day
								) === "number" &&
									typeof (
										action.selectedAge.month ||
										state.register.registerForm.age.month
									) === "number" &&
									typeof (
										action.selectedAge.year ||
										state.register.registerForm.age.year
									) === "number"
							),
						},
					},
				},
			};
		// ** SET AGE COUPLE **
		case SET_AGE_COUPLE:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						ageCoupleCase: {
							...state.register.registerForm.ageCoupleCase,
							partner1: {
								day:
									action.selectedAge.day === null
										? null
										: action.selectedAge.day ||
										  state.register.registerForm
												.ageCoupleCase.partner1.day,
								month:
									action.selectedAge.month === null
										? null
										: action.selectedAge.month ||
										  state.register.registerForm
												.ageCoupleCase.partner1.month,
								year:
									action.selectedAge.year === null
										? null
										: action.selectedAge.year ||
										  state.register.registerForm
												.ageCoupleCase.partner1.year,
							},
							isSelectedPartner1: Boolean(
								typeof (
									action.selectedAge.day ||
									state.register.registerForm.ageCoupleCase
										.partner1.day
								) === "number" &&
									typeof (
										action.selectedAge.month ||
										state.register.registerForm
											.ageCoupleCase.partner1.month
									) === "number" &&
									typeof (
										action.selectedAge.year ||
										state.register.registerForm
											.ageCoupleCase.partner1.year
									) === "number"
							),
						},
					},
				},
			};
		// ** SET AGE COUPLE PARTNER**
		case SET_AGE_COUPLE_PARTNER:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						ageCoupleCase: {
							...state.register.registerForm.ageCoupleCase,
							partner2: {
								day:
									action.selectedAge.day === null
										? null
										: action.selectedAge.day ||
										  state.register.registerForm
												.ageCoupleCase.partner2.day,
								month:
									action.selectedAge.month === null
										? null
										: action.selectedAge.month ||
										  state.register.registerForm
												.ageCoupleCase.partner2.month,
								year:
									action.selectedAge.year === null
										? null
										: action.selectedAge.year ||
										  state.register.registerForm
												.ageCoupleCase.partner2.year,
							},
							isSelectedPartner2: Boolean(
								typeof (
									action.selectedAge.day ||
									state.register.registerForm.ageCoupleCase
										.partner2.day
								) === "number" &&
									typeof (
										action.selectedAge.month ||
										state.register.registerForm
											.ageCoupleCase.partner2.month
									) === "number" &&
									typeof (
										action.selectedAge.year ||
										state.register.registerForm
											.ageCoupleCase.partner2.year
									) === "number"
							),
						},
					},
				},
			};
		// ** SET LOGIN **
		case SET_LOGIN:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						login: {
							...state.register.registerForm.login,
							login: action.login,
							errorFrontend: action.errorFrontend,
							loadingApi: false,
							successApi: false,
							loginAvailable: false,
							errorApi: false,
							errorApiMessage: null,
						},
					},
				},
			};
		// ** CHECK LOGIN AVAILABLE **
		case CHECK_LOGIN_AVAILABLE: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						login: {
							...state.register.registerForm.login,
							loadingApi: true,
							successApi: false,
							loginAvailable: false,
							errorApi: false,
							errorApiMessage: null,
						},
					},
				},
			};
		}
		case CHECK_LOGIN_AVAILABLE_SUCCESS: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						login: {
							...state.register.registerForm.login,
							loadingApi: false,
							successApi: true,
							loginAvailable: true,
						},
					},
				},
			};
		}
		case CHECK_LOGIN_AVAILABLE_FAIL: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						login: {
							...state.register.registerForm.login,
							loadingApi: false,
							successApi: false,
							errorApi: true,
							errorApiMessage: action.error || "Error",
							loginAvailable: false,
						},
					},
				},
			};
		}
		// ** SET_PASSWORD **
		case SET_PASSWORD:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						password: {
							...state.register.registerForm.password,
							password: action.password,
							errorFrontend: action.errorFrontend,
						},
					},
				},
			};
		// ** SET EMAIL **
		case SET_EMAIL:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						email: {
							...state.register.registerForm.email,
							email: action.email,
							errorFrontend: action.errorFrontend,
							loadingApi: false,
							successApi: false,
							emailAvailable: false,
							errorApi: false,
							errorApiMessage: null,
						},
					},
				},
			};
		// ** CHECK EMAIL AVAILABLE **
		case CHECK_EMAIL_AVAILABLE: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						email: {
							...state.register.registerForm.email,
							loadingApi: true,
							successApi: false,
							loginAvailable: false,
							errorApi: false,
							errorApiMessage: null,
						},
					},
				},
			};
		}
		case CHECK_EMAIL_AVAILABLE_SUCCESS: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						email: {
							...state.register.registerForm.email,
							loadingApi: false,
							successApi: true,
							loginAvailable: true,
							errorApi: false,
							errorApiMessage: null,
						},
					},
				},
			};
		}
		case CHECK_EMAIL_AVAILABLE_FAIL: {
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						email: {
							...state.register.registerForm.email,
							loadingApi: false,
							successApi: false,
							errorApi: true,
							errorApiMessage: action.error || "Error",
							emailAvailable: false,
						},
					},
				},
			};
		}
		// ** SET LOCATION **
		case SET_LOCATION:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...state.register.registerForm,
						location: {
							city: action.location.city,
							cityName: action?.location?.cityName || null,
							region: action.location.region,
							regionName: action?.location?.regionName || null,
							country: action.location.country,
							isSelected: Boolean(
								action.location.country && action.location.city
							),
						},
					},
				},
			};
		// ** CLEAR REGISTER FORM **
		case CLEAR_REGISTER_FORM:
			return {
				...state,
				register: {
					...state.register,
					registerForm: {
						...initialState.register.registerForm,
					},
				},
			};
		// ** SET_FAKE_USER_DATA **
		case SET_FAKE_USER_DATA:
			return {
				...state,
				fakeUserData: {
					...state.fakeUserData,
					...processFakeUserData(action),
				},
			};
		// ** SET_SENT_SMS_CODE_TIME **
		case SET_SENT_SMS_CODE_TIME:
			return {
				...state,
				sentSmsCodeTime: action.sentTime,
				blockTime: action.blockTime,
			};
		default:
			return state;
	}
}

export function login(username, password, fingerprint) {
	return {
		types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
		promise: (client) =>
			client
				.post(`/authorization/login/web`, {
					login: username,
					password,
					fingerprint: fingerprint || "",
				})
				.then((res) => {
					const token =
						res.headers.authorization.match(/Bearer\s(\S+)/)[1];
					client.addAuthToken(token);
					return res;
				}),
		noToast: false,
	};
}

export function register(data) {
	return {
		types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
		promise: (client) =>
			client.post(
				"/authorization/registration/web",
				{
					...data,
					fingerprint: data.fingerprint || "",
				},
				{
					headers: {
						Accept: "application/api.1.1+json",
					},
				}
			),
		noToast: false,
	};
}

export function registerVerify(login, id, hash, fingerprint = null) {
	return {
		types: [REGISTER_VERIFY, REGISTER_VERIFY_SUCCESS, REGISTER_VERIFY_FAIL],
		promise: (client) =>
			client.put("/authorization/registration/verify/web", {
				id: +id,
				hash,
				login,
				fingerprint: fingerprint || "",
			}),
		noToast: false,
	};
}

export function registerVerifySmsCode({
	login,
	id,
	hash,
	code,
	fingerprint = null,
}) {
	return {
		types: [
			REGISTER_VERIFY_SMS_CODE,
			REGISTER_VERIFY_SMS_CODE_SUCCESS,
			REGISTER_VERIFY_SMS_CODE_FAIL,
		],
		promise: (client) =>
			client.put("/authorization/registration/verify/code", {
				id: +id,
				hash,
				login,
				code,
				fingerprint: fingerprint || "",
			}),
		noToast: false,
	};
}

export function setFakeUserData(fakeUserData) {
	return {
		type: SET_FAKE_USER_DATA,
		result: fakeUserData,
	};
}

export function setSentSmsCodeTime({ sentTime, blockTime }) {
	return {
		type: SET_SENT_SMS_CODE_TIME,
		sentTime,
		blockTime,
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export function setApiToken(token) {
	return {
		type: SET_API_TOKEN,
		token,
	};
}

export function setTerms(isChecked) {
	return {
		type: SET_TERMS,
		isChecked,
	};
}

export function setGender(selectedGender) {
	return {
		type: SET_GENDER,
		selectedGender,
	};
}

export function setGenderCouple(selectedGender) {
	return {
		type: SET_GENDER_COUPLE,
		selectedGender,
	};
}

export function setGenderCouplePartner(selectedGender) {
	return {
		type: SET_GENDER_COUPLE_PARTNER,
		selectedGender,
	};
}

export function setAge({ day, month, year }) {
	return {
		type: SET_AGE,
		selectedAge: { day, month, year },
	};
}

export function setAgeCouple({ day, month, year }) {
	return {
		type: SET_AGE_COUPLE,
		selectedAge: { day, month, year },
	};
}

export function setAgeCouplePartner({ day, month, year }) {
	return {
		type: SET_AGE_COUPLE_PARTNER,
		selectedAge: { day, month, year },
	};
}

export function setLogin(login, errorFrontend = false) {
	return {
		type: SET_LOGIN,
		login,
		errorFrontend,
	};
}

export function checkLoginAvailable(login) {
	return {
		types: [
			CHECK_LOGIN_AVAILABLE,
			CHECK_LOGIN_AVAILABLE_SUCCESS,
			CHECK_LOGIN_AVAILABLE_FAIL,
		],
		promise: (client) =>
			client.post("/authorization/registration/available/login", {
				login,
			}),
		noToast: true,
	};
}

export function setPassword(password, errorFrontend = false) {
	return {
		type: SET_PASSWORD,
		password,
		errorFrontend,
	};
}

export function setEmail(email, errorFrontend = false) {
	return {
		type: SET_EMAIL,
		email,
		errorFrontend,
	};
}

export function checkEmailAvailable(email) {
	return {
		types: [
			CHECK_EMAIL_AVAILABLE,
			CHECK_EMAIL_AVAILABLE_SUCCESS,
			CHECK_EMAIL_AVAILABLE_FAIL,
		],
		promise: (client) =>
			client.post("/authorization/registration/available/email", {
				email,
			}),
		noToast: true,
	};
}

export function setLocation(location, errorFrontend = false) {
	return {
		type: SET_LOCATION,
		location,
		errorFrontend,
	};
}

export function clearRegisterForm() {
	return {
		type: CLEAR_REGISTER_FORM,
	};
}

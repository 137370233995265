// ** Third Party Componentsor from "color-js";
import styled, { css } from "styled-components";

// #####################################################

export const StyledLoadingContainer = styled("span")`
	display: inline-block;
	${({ prepend }) =>
		!prepend &&
		css`
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		`}
`;

// #####################################################

import defaults from "../../defaults";

import { vendor } from "store/vendor";

const GET_EVENT_STATUS = "up/events/GET_EVENT_STATUS";
const GET_EVENT_STATUS_SUCCESS = "up/events/GET_EVENT_STATUS_SUCCESS";
const GET_EVENT_STATUS_FAIL = "up/events/GET_EVENT_STATUS_FAIL";

const GET_MY_EVENTS = "up/events/userpanel/GET_MY_EVENTS";
const GET_MY_EVENTS_SUCCESS = "up/events/userpanelGET_MY_EVENTS_SUCCESS";
const GET_MY_EVENTS_FAIL = "up/events/userpanel/GET_MY_EVENTS_FAIL";

const REMOVE_MY_EVENT = "up/events/REMOVE_MY_EVENT";
const REMOVE_MY_EVENT_SUCCESS = "up/events/REMOVE_MY_EVENT_SUCCESS";
const REMOVE_MY_EVENT_FAIL = "up/events/REMOVE_MY_EVENT_FAIL";

const GET_EVENT_INVITES = "up/events/GET_EVENT_INVITES";
const GET_EVENT_INVITES_SUCCESS = "up/events/GET_EVENT_INVITES_SUCCESS";
const GET_EVENT_INVITES_FAIL = "up/events/GET_EVENT_INVITES_FAIL";

const REMOVE_EVENT_INVITE = "up/events/REMOVE_EVENT_INVITE";
const REMOVE_EVENT_INVITE_SUCCESS = "up/events/REMOVE_EVENT_INVITE_SUCCESS";
const REMOVE_EVENT_INVITE_FAIL = "up/events/REMOVE_EVENT_INVITE_FAIL";

const GET_INVITATION_SEND = "up/events/GET_INVITATION_SEND";
const GET_INVITATION_SEND_SUCCESS = "up/events/GET_INVITATION_SEND_SUCCESS";
const GET_INVITATION_SEND_FAIL = "up/events/GET_INVITATION_SEND_FAIL";

const REMOVE_INVITATION_SEND = "up/events/REMOVE_INVITATION_SEND";
const REMOVE_INVITATION_SEND_SUCCESS =
	"up/events/REMOVE_INVITATION_SEND_SUCCESS";
const REMOVE_INVITATION_SEND_FAIL = "up/events/REMOVE_INVITATION_SEND_FAIL";

const GET_EVENT_MEMBER = "up/events/GET_EVENT_MEMBER";
const GET_EVENT_MEMBER_SUCCESS = "up/events/GET_EVENT_MEMBER_SUCCESS";
const GET_EVENT_MEMBER_FAIL = "up/events/GET_EVENT_MEMBER_FAIL";

const REMOVE_FROM_EVENT = "up/events/REMOVE_FROM_EVENT";
const REMOVE_FROM_EVENT_SUCCESS = "up/events/REMOVE_FROM_EVENT_SUCCESS";
const REMOVE_FROM_EVENT_FAIL = "up/events/REMOVE_FROM_EVENT_SUCCESS";

const GET_FRIENDS_SEND_INVITES = "up/events/GET_FRIENDS_SEND_INVITES";
const GET_FRIENDS_SEND_INVITES_SUCCESS =
	"up/events/GET_FRIENDS_SEND_INVITES_SUCCESS";
const GET_FRIENDS_SEND_INVITES_FAIL = "up/events/GET_FRIENDS_SEND_INVITES_FAIL";

const GET_SEND_INVITES = "up/events/GET_SEND_INVITES";
const GET_SEND_INVITES_SUCCESS = "up/events/GET_SEND_INVITES_SUCCESS";
const GET_SEND_INVITES_FAIL = "up/events/GET_SEND_INVITES_FAIL";

const SEND_INVITES = "up/events/SEND_INVITES";
const SEND_INVITES_SUCCESS = "up/events/SEND_INVITES_SUCCESS";
const SEND_INVITES_FAIL = "up/events/SEND_INVITES_FAIL";

const DELETE_SEND_INVITES = "up/events/DELETE_SEND_INVITES";
const DELETE_SEND_INVITES_SUCCESS = "up/events/DELETE_SEND_INVITES_SUCCESS";
const DELETE_SEND_INVITES_FAIL = "up/events/DELETE_SEND_INVITES_FAIL";

const GET_BLOCKED_USERS = "up/events/GET_BLOCKED_USERS";
const GET_BLOCKED_USERS_SUCCESS = "up/events/GET_BLOCKED_USERS_SUCCESS";
const GET_BLOCKED_USERS_FAIL = "up/events/GET_BLOCKED_USERS_FAIL";

const DELETE_BLOCKED_USERS = "up/events/DELETE_BLOCKED_USERS";
const DELETE_BLOCKED_USERS_SUCCESS = "up/events/DELETE_BLOCKED_USERS_SUCCESS";
const DELETE_BLOCKED_USERS_FAIL = "up/events/DELETE_BLOCKED_USERS_FAIL";

const GET_EVENT_ADMINS = "up/events/GET_EVENT_ADMINS";
const GET_EVENT_ADMINS_SUCCESS = "up/events/GET_EVENT_ADMINS_SUCCESS";
const GET_EVENT_ADMINS_FAIL = "up/events/GET_EVENT_ADMINS_FAIL";

const DELETE_EVENT_ADMINS = "up/events/DELETE_EVENT_ADMINS";
const DELETE_EVENT_ADMINS_SUCCESS = "up/events/DELETE_EVENT_ADMINS_SUCCESS";
const DELETE_EVENT_ADMINS_FAIL = "up/events/DELETE_EVENT_ADMINS_FAIL";

const GET_EVENT_APPLICATIONS = "up/events/GET_EVENT_APPLICATIONS_ADMINS";
const GET_EVENT_APPLICATIONS_SUCCESS =
	"up/events/GET_EVENT_APPLICATIONS_SUCCESS";
const GET_EVENT_APPLICATIONS_FAIL = "up/events/GET_EVENT_APPLICATIONS_FAIL";

const DELETE_USER_FROM_APPLICATIONS =
	"up/events/DELETE_USER_FROM_APPLICATIONS_ADMINS";
const DELETE_USER_FROM_APPLICATIONS_SUCCESS =
	"up/events/DELETE_USER_FROM_APPLICATIONS_SUCCESS";
const DELETE_USER_FROM_APPLICATIONS_FAIL =
	"up/events/DELETE_USER_FROM_APPLICATIONS_FAIL";

const GET_EVENT_MEMBERS = "up/events/GET_EVENT_MEMBERS";
const GET_EVENT_MEMBERS_SUCCESS = "up/events/GET_EVENT_MEMBERS_SUCCESS";
const GET_EVENT_MEMBERS_FAIL = "up/events/GET_EVENT_MEMBERS_FAIL";

const DELETE_USER_FROM_MEMBERS = "up/events/DELETE_USER_FROM_MEMBERS";
const DELETE_USER_FROM_MEMBERS_SUCCESS = "up/events/DELETE_USER_FROM_MEMBERS";
const DELETE_USER_FROM_MEMBERS_FAIL = "up/events/DELETE_USER_FROM_MEMBERS";

const BLOCK_USERS_IN_EVENT = "up/events/BLOCK_USERS_IN_EVENT";
const BLOCK_USERS_IN_EVENT_SUCCESS = "up/events/BLOCK_USERS_IN_EVENT_SUCCESS";
const BLOCK_USERS_IN_EVENT_FAIL = "up/events/BLOCK_USERS_IN_EVENT_FAIL";

const SET_ADMIN_EVENT = "up/events/SET_ADMIN_EVENT";
const SET_ADMIN_EVENT_SUCCESS = "up/events/SET_ADMIN_EVENT_SUCCESS";
const SET_ADMIN_EVENT_FAIL = "up/events/SET_ADMIN_EVENT_FAIL";

const BLOCK_USERS_IN_APPLICATIONS = "up/events/BLOCK_USERS_IN_APPLICATIONS";
const BLOCK_USERS_IN_APPLICATIONS_SUCCESS =
	"up/events/BLOCK_USERS_IN_APPLICATIONS_SUCCESS";
const BLOCK_USERS_IN_APPLICATIONS_FAIL =
	"up/events/BLOCK_USERS_IN_APPLICATIONS_FAIL";

const ACCEPT_USERS_IN_APPLICATIONS = "up/events/ACCEPT_USERS_IN_APPLICATIONS";
const ACCEPT_USERS_IN_APPLICATIONS_SUCCESS =
	"up/events/ACCEPT_USERS_IN_APPLICATIONS_SUCCESS";
const ACCEPT_USERS_IN_APPLICATIONS_FAIL =
	"up/events/ACCEPT_USERS_IN_APPLICATIONS_FAIL";

const GET_EVENT_POSTS = "up/events/GET_EVENT_POSTS";
const GET_EVENT_POSTS_SUCCESS = "up/events/GET_EVENT_POSTS_SUCCESS";
const GET_EVENT_POSTS_FAIL = "up/events/GET_EVENT_POSTS_FAIL";

const DELETE_POST_FROM_EVENT = "up/events/DELETE_POST_FROM_EVENT";
const DELETE_POST_FROM_EVENT_SUCCESS =
	"up/events/DELETE_POST_FROM_EVENT_SUCCESS";
const DELETE_POST_FROM_EVENT_FAIL = "up/events/DELETE_POST_FROM_EVENT_FAIL";

const DELETE_COMMENTS_FROM_EVENT_POST =
	"up/events/DELETE_COMMENTS_FROM_EVENT_POST";
const DELETE_COMMENTS_FROM_EVENT_POST_SUCCESS =
	"up/events/DELETE_COMMENTS_FROM_EVENT_POST_SUCCESS";
const DELETE_COMMENTS_FROM_EVENT_POST_FAIL =
	"up/events/DELETE_COMMENTS_FROM_EVENT_POST_FAIL";

const ACCEPT_INVITE_TO_EVENT = "up/events/ACCEPT_INVITE_TO_EVENT";
const ACCEPT_INVITE_TO_EVENT_SUCCESS =
	"up/events/ACCEPT_INVITE_TO_EVENT_SUCCESS";
const ACCEPT_INVITE_TO_EVENT_FAIL = "up/events/ACCEPT_INVITE_TO_EVENT_FAIL";

const UPDATE_EVENT = "up/events/UPDATE_EVENT";
const UPDATE_EVENT_SUCCESS = "up/events/UPDATE_EVENT_SUCCESS";
const UPDATE_EVENT_FAIL = "up/events/UPDATE_EVENT_FAIL";

const GET_EVENT_TO_EDIT = "up/events/GET_EVENT_TO_EDIT";
const GET_EVENT_TO_EDIT_SUCCESS = "up/events/GET_EVENT_TO_EDIT_SUCCESS";
const GET_EVENT_TO_EDIT_FAIL = "up/events/GET_EVENT_TO_EDIT_FAIL";

const EVENT_AVATAR_UPLOAD_PROGRESS = "up/events/EVENT_AVATAR_UPLOAD_PROGRESS";

const initialState = defaults.userpanel.events;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_EVENT_STATUS:
			if (action.eventId === String(state.eventStatus.id)) {
				return {
					...state,
					eventStatus: {
						...state.eventStatus,
						loading: true,
					},
				};
			}
			return {
				...state,
				eventStatus: {
					...initialState.eventStatus,
					loading: true,
				},
			};
		case GET_EVENT_STATUS_SUCCESS:
			return {
				...state,
				eventStatus: {
					...state.eventStatus,
					loading: false,
					loaded: true,
					id: action.result.data.id,
					name: action.result.data.name,
					ended: action.result.data.event_end,
					status: action.result.data.your_status,
				},
			};
		case GET_EVENT_STATUS_FAIL:
			return {
				...state,
				eventStatus: {
					...initialState.eventStatus,
					loading: false,
					loaded: true,
				},
			};

		case GET_MY_EVENTS:
			return {
				...state,
				myEvents: {
					...state.myEvents,
					loading: true,
				},
			};
		case GET_MY_EVENTS_SUCCESS:
			return {
				...state,
				myEvents: {
					...state.myEvents,
					loading: false,
					loaded: true,
					data: action.result.data?.events ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_MY_EVENTS_FAIL:
			return {
				...state,
				myEvents: {
					...initialState.myEvents,
					loading: false,
					loaded: true,
				},
			};
		case REMOVE_MY_EVENT:
			return {
				...state,
				myEvents: {
					...state.myEvents,
					whileRemoving: true,
				},
			};
		case REMOVE_MY_EVENT_SUCCESS:
			return {
				...state,
				myEvents: {
					...state.myEvents,
					whileRemoving: false,
				},
			};
		case REMOVE_MY_EVENT_FAIL:
			return {
				...state,
				myEvents: {
					...state.myEvents,
					whileRemoving: false,
				},
			};
		case GET_EVENT_INVITES:
			return {
				...state,
				invite: {
					...state.invite,
					loading: true,
				},
			};
		case GET_EVENT_INVITES_SUCCESS:
			return {
				...state,
				invite: {
					...state.invite,
					loading: false,
					loaded: true,
					events: action.result.data?.events ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_INVITES_FAIL:
			return {
				...state,
				invite: {
					...initialState.invite,
					loading: false,
				},
			};
		case REMOVE_EVENT_INVITE:
			return {
				...state,
				invite: {
					...state.invite,
					whileRemoving: true,
				},
			};
		case REMOVE_EVENT_INVITE_SUCCESS:
			return {
				...state,
				invite: {
					...state.invite,
					whileRemoving: false,
					events: state.invite.events.filter(
						(event) => !action.data?.includes(event.id) ?? []
					),
				},
			};
		case REMOVE_EVENT_INVITE_FAIL:
			return {
				...state,
				invite: {
					...initialState.invite,
					whileRemoving: false,
				},
			};
		case GET_INVITATION_SEND:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: true,
				},
			};
		case GET_INVITATION_SEND_SUCCESS:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					loading: false,
					loaded: true,
					events: action.result.data?.events ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_INVITATION_SEND_FAIL:
			return {
				...state,
				invitationsSend: {
					...initialState.invitationsSend,
					loaded: true,
					loading: false,
				},
			};
		case REMOVE_INVITATION_SEND:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					whileRemoving: true,
				},
			};
		case REMOVE_INVITATION_SEND_SUCCESS:
			return {
				...state,
				invitationsSend: {
					...state.invitationsSend,
					whileRemoving: false,
					events: state.invitationsSend.events.filter(
						(event) => !action.data?.includes(event.id)
					),
				},
			};
		case REMOVE_INVITATION_SEND_FAIL:
			return {
				...state,
				invitationsSend: {
					...initialState.invitationsSend,
					whileRemoving: false,
				},
			};
		case GET_EVENT_MEMBER:
			return {
				...state,
				member: {
					...initialState.member,
					loading: true,
				},
			};
		case GET_EVENT_MEMBER_SUCCESS:
			return {
				...state,
				member: {
					...state.member,
					loading: false,
					loaded: true,
					events: action.result.data?.events ?? [],
					pagination: {
						totalPages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_MEMBER_FAIL:
			return {
				...state,
				member: {
					...initialState.member,
					loading: false,
				},
			};
		case REMOVE_FROM_EVENT:
			return {
				...state,
				member: {
					...state.member,
					whileRemoving: true,
				},
			};
		case REMOVE_FROM_EVENT_SUCCESS:
			return {
				...state,
				member: {
					...state.member,
					whileRemoving: false,
					events: state.member.events.filter(
						(event) => !action.data?.includes(event.id)
					),
				},
			};
		case REMOVE_FROM_EVENT_FAIL:
			return {
				...state,
				member: {
					...initialState.member,
					whileRemoving: false,
				},
			};
		case GET_FRIENDS_SEND_INVITES:
			if (action.eventId === state.friendsSendInvites.eventId)
				return {
					...state,
					friendsSendInvites: {
						...state.friendsSendInvites,
						loading: true,
					},
				};
			return {
				...state,
				friendsSendInvites: {
					...initialState.friendsSendInvites,
					loading: true,
				},
			};
		case GET_FRIENDS_SEND_INVITES_SUCCESS:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					loading: false,
					loaded: true,
					eventId: action?.eventId,
					accepted: action?.result?.data?.accepted ?? true,
					data: action?.result?.data?.users ?? [],
					pagination: {
						currentpage:
							action?.result?.data?.pagination.currentpage ?? 1,
						totalpages:
							action?.result?.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_FRIENDS_SEND_INVITES_FAIL:
			return {
				...state,
				friendsSendInvites: {
					...initialState.friendsSendInvites,
					loading: false,
					loaded: true,
				},
			};
		case SEND_INVITES:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: true,
				},
			};
		case SEND_INVITES_SUCCESS:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: false,
				},
			};
		case SEND_INVITES_FAIL:
			return {
				...state,
				friendsSendInvites: {
					...state.friendsSendInvites,
					whileAdding: false,
				},
			};
		case GET_SEND_INVITES:
			if (action.eventId === state.sendInvites.eventId)
				return {
					...state,
					sendInvites: {
						...state.sendInvites,
						loading: true,
					},
				};

			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					loading: true,
				},
			};
		case GET_SEND_INVITES_SUCCESS:
			return {
				...state,
				sendInvites: {
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_SEND_INVITES_FAIL:
			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_SEND_INVITES:
			return {
				...state,
				sendInvites: {
					...state.sendInvites,
					whileRemoving: true,
				},
			};
		case DELETE_SEND_INVITES_SUCCESS:
			return {
				...state,
				sendInvites: {
					...state.sendInvites,
					whileRemoving: false,
				},
			};
		case DELETE_SEND_INVITES_FAIL:
			return {
				...state,
				sendInvites: {
					...initialState.sendInvites,
					whileRemoving: false,
				},
			};
		case GET_EVENT_TO_EDIT:
			if (action.eventId === String(state.eventEdit.eventId))
				return {
					...state,
					eventEdit: {
						...state.eventEdit,
						loading: true,
					},
				};
			return {
				...state,
				eventEdit: {
					...initialState.eventEdit,
					loading: true,
				},
			};
		case GET_EVENT_TO_EDIT_SUCCESS:
			return {
				...state,
				eventEdit: {
					loading: false,
					loaded: true,
					eventId: action.result.data.id,
					city: action.result.data.city,
					region: action.result.data.region,
					country: action.result.data.country,
					date: action.result.data.event_date,
					time: action.result.data.event_time,
					name: action.result.data.name,
					avatar: action.result.data.avatar,
					description: action.result.data.description,
					descriptionRaw: action.result.data.description_raw,
					auto_add: action.result.data.auto_add,
					created_date: action.result.data.created_date,
					accepted: {
						profile: action.result.data.accepted.profile,
						description: action.result.data.accepted.description,
						avatar: action.result.data.accepted.avatar,
						city: action.result.data.accepted.city,
					},
					sex_post: {
						woman: action.result.data.sex_post.woman,
						man: action.result.data.sex_post.man,
						couple: action.result.data.sex_post.couple,
						transsexual: action.result.data.sex_post.transsexual,
					},
					sex_invite: {
						woman: action.result.data.sex_invite.woman,
						man: action.result.data.sex_invite.man,
						couple: action.result.data.sex_invite.couple,
						transsexual: action.result.data.sex_invite.transsexual,
					},
				},
			};
		case GET_EVENT_TO_EDIT_FAIL:
			return {
				...state,
				eventEdit: {
					...initialState.eventEdit,
					loading: false,
					loaded: true,
				},
			};
		case GET_EVENT_MEMBERS:
			if (action.eventId === state.eventMembers.eventId) {
				return {
					...state,
					eventMembers: {
						...state.eventMembers,
						loading: true,
					},
				};
			}
			return {
				...state,
				eventMembers: {
					...initialState.eventMembers,
					loading: true,
				},
			};
		case GET_EVENT_MEMBERS_SUCCESS:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_MEMBERS_FAIL:
			return {
				...state,
				eventMembers: {
					...initialState.eventMembers,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_USER_FROM_MEMBERS:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileRemoving: true,
				},
			};
		case DELETE_USER_FROM_MEMBERS_SUCCESS:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileRemoving: false,
				},
			};
		case DELETE_USER_FROM_MEMBERS_FAIL:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileRemoving: false,
				},
			};
		case BLOCK_USERS_IN_EVENT:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileBlocking: true,
				},
			};
		case BLOCK_USERS_IN_EVENT_SUCCESS:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileBlocking: false,
				},
			};
		case BLOCK_USERS_IN_EVENT_FAIL:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileBlocking: false,
				},
			};
		case SET_ADMIN_EVENT:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileAdding: true,
				},
			};
		case SET_ADMIN_EVENT_SUCCESS:
			return {
				...state,
				eventMembers: {
					...state.eventMembers,
					whileAdding: false,
				},
			};
		case SET_ADMIN_EVENT_FAIL:
			return {
				...state,
				eventMembers: {
					...initialState.eventMembers,
					whileAdding: false,
				},
			};
		case GET_EVENT_ADMINS:
			if (action.eventId === state.eventAdmins.eventId) {
				return {
					...state,
					eventAdmins: {
						...state.eventAdmins,
						loading: true,
						eventId: action.eventId,
					},
				};
			}
			return {
				...state,
				eventAdmins: {
					...initialState.eventAdmins,
					loading: true,
				},
			};
		case GET_EVENT_ADMINS_SUCCESS:
			return {
				...state,
				eventAdmins: {
					...state.eventAdmins,
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result?.data.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_ADMINS_FAIL:
			return {
				...state,
				eventAdmins: {
					...initialState.eventAdmins,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_EVENT_ADMINS:
			return {
				...state,
				eventAdmins: {
					...state.eventAdmins,
					whileRemoving: true,
				},
			};
		case DELETE_EVENT_ADMINS_SUCCESS:
			return {
				...state,
				eventAdmins: {
					...state.eventAdmins,
					whileRemoving: false,
				},
			};
		case DELETE_EVENT_ADMINS_FAIL:
			return {
				...state,
				eventAdmins: {
					...state.eventAdmins,
					whileRemoving: false,
				},
			};
		case GET_EVENT_APPLICATIONS:
			if (action.eventId === state.eventApplications.eventId)
				return {
					...state,
					eventApplications: {
						...state.eventApplications,
						loading: true,
					},
				};
			return {
				...state,
				eventApplications: {
					...initialState.eventApplications,
					loading: true,
				},
			};
		case GET_EVENT_APPLICATIONS_SUCCESS:
			return {
				...state,
				eventApplications: {
					...state.eventApplications,
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_APPLICATIONS_FAIL:
			return {
				...state,
				eventApplications: {
					...initialState.eventApplications,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS:
			return {
				...state,
				eventApplications: {
					...state.eventApplications,
					whileRemoving: true,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS_SUCCESS:
			return {
				...state,
				eventApplications: {
					...state.eventApplications,
					whileRemoving: false,
				},
			};
		case DELETE_USER_FROM_APPLICATIONS_FAIL:
			return {
				...state,
				eventApplications: {
					...state.eventApplications,
					whileRemoving: false,
				},
			};
		case GET_BLOCKED_USERS:
			if (action.eventId === state.blockedUsers.eventId)
				return {
					...state,
					blockedUsers: {
						...state.blockedUsers,
						loading: true,
					},
				};
			return {
				...state,
				blockedUsers: {
					...initialState.blockedUsers,
					loading: true,
				},
			};
		case GET_BLOCKED_USERS_SUCCESS:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result.data?.users ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_BLOCKED_USERS_FAIL:
			return {
				...state,
				blockedUsers: {
					...initialState.blockedUsers,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_BLOCKED_USERS:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: true,
				},
			};
		case DELETE_BLOCKED_USERS_SUCCESS:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: false,
				},
			};
		case DELETE_BLOCKED_USERS_FAIL:
			return {
				...state,
				blockedUsers: {
					...state.blockedUsers,
					whileRemoving: false,
				},
			};
		case GET_EVENT_POSTS:
			if (action.eventId === state.eventPosts.eventId)
				return {
					...state,
					eventPosts: {
						...state.eventPosts,
						loading: true,
					},
				};
			return {
				...state,
				eventPosts: {
					...initialState.eventPosts,
					loading: true,
				},
			};
		case GET_EVENT_POSTS_SUCCESS:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					loading: false,
					loaded: true,
					eventId: action.eventId,
					data: action.result.data?.posts ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_EVENT_POSTS_FAIL:
			return {
				...state,
				eventPosts: {
					...initialState.eventPosts,
					loading: false,
					loaded: true,
				},
			};
		case DELETE_POST_FROM_EVENT:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: true,
				},
			};
		case DELETE_POST_FROM_EVENT_SUCCESS:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: false,
				},
			};
		case DELETE_POST_FROM_EVENT_FAIL:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: false,
				},
			};
		case DELETE_COMMENTS_FROM_EVENT_POST:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: true,
				},
			};
		case DELETE_COMMENTS_FROM_EVENT_POST_SUCCESS:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: false,
				},
			};
		case DELETE_COMMENTS_FROM_EVENT_POST_FAIL:
			return {
				...state,
				eventPosts: {
					...state.eventPosts,
					whileRemoving: false,
				},
			};
		case UPDATE_EVENT:
			return {
				...state,
				avatarUploadProgress: 0,
			};
		case EVENT_AVATAR_UPLOAD_PROGRESS:
			return {
				...state,
				avatarUploadProgress: action.avatarUploadProgress,
			};
		default:
			return state;
	}
}

/**
 * Pobiera informacje o imprezie.
 * @api GET getEventById
 * @param {Number} id identyfikator imprezy
 */

export function getEventStatus(eventId) {
	return {
		types: [
			GET_EVENT_STATUS,
			GET_EVENT_STATUS_SUCCESS,
			GET_EVENT_STATUS_FAIL,
		],
		promise: (client) =>
			client.get(`/event`, {
				params: {
					id: eventId,
				},
			}),
		eventId,
	};
}

/**
 * Zwraca listę aktualnych imprez
 * @api GET getEventsMy
 * @param {Number} page numer strony
 */
export function getMyEvents(page) {
	return {
		types: [GET_MY_EVENTS, GET_MY_EVENTS_SUCCESS, GET_MY_EVENTS_FAIL],
		promise: (client) =>
			client.get(`/event/admin`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa impreze z listy imprez.
 * @api DELETE deleteEventMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów imprez
 */
export function removeEvents(ids) {
	return {
		types: [REMOVE_MY_EVENT, REMOVE_MY_EVENT_SUCCESS, REMOVE_MY_EVENT_FAIL],
		promise: (client) =>
			client.delete(`/event/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Zwraca listę aktualnych zaproszeń do imprez
 * @api GET getEventsInvite
 * @param {Number} page numer strony
 */
export function getInviteEvent(page) {
	return {
		types: [
			GET_EVENT_INVITES,
			GET_EVENT_INVITES_SUCCESS,
			GET_EVENT_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/event/invite`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa impreze z listy imprez zaproszeń.
 * @api DELETE deleteEventsInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów imprez
 */
export function removeInvites(ids) {
	return {
		types: [
			REMOVE_EVENT_INVITE,
			REMOVE_EVENT_INVITE_SUCCESS,
			REMOVE_EVENT_INVITE_FAIL,
		],
		promise: (client) =>
			client.delete(`/event/invite/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę aktualnych imprez, do których zostało wysłana prośba o dołączenie.
 * @api GET getEventsSendInvite
 * @param {Number} page numer strony
 */
export function getInvitationSend(page) {
	return {
		types: [
			GET_INVITATION_SEND,
			GET_INVITATION_SEND_SUCCESS,
			GET_INVITATION_SEND_FAIL,
		],
		promise: (client) =>
			client.get(`/event/send-invite`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa impreze z listy wysłanych zaproszeń do imprez.
 * @api DELETE deleteEventsSendInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów imprez
 */
export function removeInvitationSend(ids) {
	return {
		types: [
			REMOVE_INVITATION_SEND,
			REMOVE_INVITATION_SEND_SUCCESS,
			REMOVE_INVITATION_SEND_FAIL,
		],
		promise: (client) =>
			client.delete(`/event/send-invite/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
		data: ids,
	};
}

/**
 * Zwraca listę aktualnych imprez, do których należy user.
 * @api GET getEventsSendInvite
 * @param {Number} page numer strony
 */
export function getEventMember(page) {
	return {
		types: [
			GET_EVENT_MEMBER,
			GET_EVENT_MEMBER_SUCCESS,
			GET_EVENT_MEMBER_FAIL,
		],
		promise: (client) =>
			client.get(`/event/belong`, {
				params: {
					page,
				},
			}),
	};
}

/**
 * Usuwa użytkownika z listy użytkowników w danej imprezie.
 * @api DELETE deleteEventsSendInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów imprez
 */
export function removeFromEvent(ids) {
	return {
		types: [
			REMOVE_FROM_EVENT,
			REMOVE_FROM_EVENT_SUCCESS,
			REMOVE_FROM_EVENT_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/leave/multiple`, {
				params: {
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste uzytkowników którem możemy wysłać zaproszenie do imprezy.
 * @api GET getFriendsSendInvites
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} pageIndex numer strony
 */
export function getFriendsSendInvites(eventId, pageIndex) {
	return {
		types: [
			GET_FRIENDS_SEND_INVITES,
			GET_FRIENDS_SEND_INVITES_SUCCESS,
			GET_FRIENDS_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user/invite-send/friends`, {
				params: {
					id: eventId,
					page: pageIndex,
				},
			}),
		eventId,
	};
}

/**
 * Pobiera liste uzytkowników których zaprosilismy do danej do imprezy.
 * @api GET getSendInvites
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} pageIndex numer strony
 */
export function getSendInvites(eventId, pageIndex) {
	return {
		types: [
			GET_SEND_INVITES,
			GET_SEND_INVITES_SUCCESS,
			GET_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user/invite-send`, {
				params: {
					id: eventId,
					page: pageIndex,
				},
			}),
		eventId,
	};
}

/**
 * Dodaje użytkowników do listy zaproszonych do danej imprezy
 * @api POST inviteToEventMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function inviteToEvent(eventId, ids) {
	return {
		types: [SEND_INVITES, SEND_INVITES_SUCCESS, SEND_INVITES_FAIL],
		promise: (client) =>
			client.post(
				`/event-user/invite-send/multiple?id=${eventId}&ids=${ids.join(
					","
				)}`
			),
	};
}

/**
 * Usuwa użytkowników z listy zaproszonych do danej imprezy
 * @api DELETE deleteInviteFromEventMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteInviteToEvent(eventId, ids) {
	return {
		types: [
			DELETE_SEND_INVITES,
			DELETE_SEND_INVITES_SUCCESS,
			DELETE_SEND_INVITES_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/invite-send/multiple`, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste zablokowanych uzytkowników w danej imprezie.
 * @api GET getBlockUsers
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} pageIndex numer strony
 */
export function getBlockUsers(eventId, pageIndex) {
	return {
		types: [
			GET_BLOCKED_USERS,
			GET_BLOCKED_USERS_SUCCESS,
			GET_BLOCKED_USERS_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user/block`, {
				params: {
					id: eventId,
					page: pageIndex,
				},
			}),
		eventId,
	};
}

/**
 * Usuwa użytkowników z listy zbanowanych danej imprezy
 * @api DELETE deleteBlockUserEventMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteBlockUserEvent(eventId, ids) {
	return {
		types: [
			DELETE_BLOCKED_USERS,
			DELETE_BLOCKED_USERS_SUCCESS,
			DELETE_BLOCKED_USERS_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/block/multiple`, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Usuwa użytkowników z listy właścicieli danej imprezy
 * @api DELETE deleteAdminFromEventMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów użytkowników
 */
export function deleteAdminFromEvent(eventId, ids) {
	return {
		types: [
			DELETE_EVENT_ADMINS,
			DELETE_EVENT_ADMINS_SUCCESS,
			DELETE_EVENT_ADMINS_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/admins/multiple`, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste zgłoszeń do imprezy.
 * @api GET getUsersSendInvites
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} pageIndex numer strony
 */
export function getUsersSendInvites(eventId, pageIndex) {
	return {
		types: [
			GET_EVENT_APPLICATIONS,
			GET_EVENT_APPLICATIONS_SUCCESS,
			GET_EVENT_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user/invite-user`, {
				params: {
					id: eventId,
					page: pageIndex,
				},
			}),
		eventId,
	};
}

/**
 * Usuwa użytkownika z listy do zatwierdzenia w danej imprezie.
 * @api DELETE deleteEventsSendInviteMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function removeUserFromApplications(eventId, ids) {
	return {
		types: [
			DELETE_USER_FROM_APPLICATIONS,
			DELETE_USER_FROM_APPLICATIONS_SUCCESS,
			DELETE_USER_FROM_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/multiple`, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera liste userow w imprezie.
 * @api GET getEventUsersById
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} pageIndex numer strony
 */
export function getEventUsers(eventId, pageIndex) {
	return {
		types: [
			GET_EVENT_MEMBERS,
			GET_EVENT_MEMBERS_SUCCESS,
			GET_EVENT_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user`, {
				params: {
					id: eventId,
					page: pageIndex,
				},
			}),
		eventId,
	};
}

/**
 * Usuwa użytkownika z listy użytkowników w danej imprezie.
 * @api DELETE deleteEventsSendInviteMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function removeUserFromMembers(eventId, ids) {
	return {
		types: [
			DELETE_USER_FROM_MEMBERS,
			DELETE_USER_FROM_MEMBERS_SUCCESS,
			DELETE_USER_FROM_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-user/multiple`, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Blokuje użytkownika w danej imprezie.
 * @api POST blockUserEventMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function blockUsersInEvent(eventId, ids) {
	return {
		types: [
			BLOCK_USERS_IN_EVENT,
			BLOCK_USERS_IN_EVENT_SUCCESS,
			BLOCK_USERS_IN_EVENT_FAIL,
		],
		promise: (client) =>
			client.post(
				`/event-user/block/multiple?id=${eventId}&ids=${ids.join(",")}`
			),
	};
}

/**
 * Dodaje użytkownika do właścicieli imprezy.
 * @api PUT setAdminEventMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function setAdminEvent(eventId, ids) {
	return {
		types: [SET_ADMIN_EVENT, SET_ADMIN_EVENT_SUCCESS, SET_ADMIN_EVENT_FAIL],
		promise: (client) =>
			client.put(`/event-user/admins/multiple`, null, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Blokuje użytkownika w zgłoszeniach do danej imprezy.
 * @api POST blockUserEventMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function blockUsersInApplications(eventId, ids) {
	return {
		types: [
			BLOCK_USERS_IN_APPLICATIONS,
			BLOCK_USERS_IN_APPLICATIONS_SUCCESS,
			BLOCK_USERS_IN_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.post(`/event-user/block/multiple`, null, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Przyjmuje użytkownika w zgłoszeniach do danej imprezy.
 * @api PUT acceptUserToEventMultiple
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} ids tablica identyfikatorów userów
 */
export function acceptUsersInApplications(eventId, ids) {
	return {
		types: [
			ACCEPT_USERS_IN_APPLICATIONS,
			ACCEPT_USERS_IN_APPLICATIONS_SUCCESS,
			ACCEPT_USERS_IN_APPLICATIONS_FAIL,
		],
		promise: (client) =>
			client.put(`/event-user/multiple`, null, {
				params: {
					id: eventId,
					ids: ids.join(","),
				},
			}),
	};
}

/**
 * Pobiera posty z danej imprezy.
 * @api GET getEventPosts
 * @param {Number} eventId identyfikator imprezy
 * @param {Array.<Number>} pageIndex index tablicy
 */
export function getEventPosts(eventId, pageIndex, comment = false) {
	return {
		types: [GET_EVENT_POSTS, GET_EVENT_POSTS_SUCCESS, GET_EVENT_POSTS_FAIL],
		promise: (client) =>
			client.get(`/event-content`, {
				params: {
					id: eventId,
					page: pageIndex,
					comment,
					commentNoLimit: true,
				},
			}),
		eventId,
	};
}

/**
 * Usuwa post w danej imprezie.
 * @api DELETE deleteEventPost
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} id identyfikator posta
 */
export function deleteEventPost(eventId, id) {
	return {
		types: [
			DELETE_POST_FROM_EVENT,
			DELETE_POST_FROM_EVENT_SUCCESS,
			DELETE_POST_FROM_EVENT_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-content`, {
				params: {
					id: eventId,
					postId: id,
				},
			}),
	};
}

/**
 * Usuwa komentarz do danego posta w imprezie.
 * @api DELETE deleteEventPostComment
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} commentId identyfikator komentarz
 */
export function deleteEventPostComment(eventId, commentId) {
	return {
		types: [
			DELETE_COMMENTS_FROM_EVENT_POST,
			DELETE_COMMENTS_FROM_EVENT_POST_SUCCESS,
			DELETE_COMMENTS_FROM_EVENT_POST_FAIL,
		],
		promise: (client) =>
			client.delete(`/event-content/comment`, {
				params: {
					id: eventId,
					commentId,
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

/**
 * Akceptuje zaproszenia do imprez;
 * @api POST acceptEventsInviteMultiple
 * @param {Array.<Number>} ids tablica identyfikatorów imprez
 */
export function acceptEventsInvite(ids) {
	return {
		types: [
			ACCEPT_INVITE_TO_EVENT,
			ACCEPT_INVITE_TO_EVENT_SUCCESS,
			ACCEPT_INVITE_TO_EVENT_FAIL,
		],
		promise: (client) =>
			client.post(`/event/invite/multiple?ids=${ids.join(",")}`),
	};
}

/**
 * Edytuje informacje o imprezie.
 * @api Post updateEvent
 * @param {Number} data nowe dane
 */
export function updateEvent(data, avatar, token) {
	const formData = new FormData();

	formData.append("data", JSON.stringify(data));

	if (avatar) {
		formData.append("avatar", avatar);
	}

	formData.append("token", token);

	return {
		types: [UPDATE_EVENT, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAIL],
		promise: (client) =>
			client.post(`/event/edit`, formData, {
				onUploadProgress: (e) => {
					vendor.store.dispatch(
						setEventAvatarUploadProgress(
							Math.round((e.loaded * 100) / e.total)
						)
					);
				},
			}),
	};
}
/**
 * Pobiera informacje o imprezie.
 * @api GET getEventById
 * @param {Number} eventId identyfikator imprezy
 */
export function getEventToEdit(eventId) {
	return {
		types: [
			GET_EVENT_TO_EDIT,
			GET_EVENT_TO_EDIT_SUCCESS,
			GET_EVENT_TO_EDIT_FAIL,
		],
		promise: (client) =>
			client.get(`/event/edit`, {
				params: {
					id: eventId,
				},
			}),
		eventId,
	};
}

export function setEventAvatarUploadProgress(progress) {
	return {
		type: EVENT_AVATAR_UPLOAD_PROGRESS,
		avatarUploadProgress: progress,
	};
}

import { searchSexCustomSort } from "./searchSexCustomSort";

export const processUserData = (action) => {
	const partners = {
		...(action.result.data.sex === "couple"
			? {
					birthday: [
						action.result.data.partner_1.birthday,
						action.result.data.partner_2.birthday,
					],
					sex: [
						action.result.data.partner_1.sex,
						action.result.data.partner_2.sex,
					],
					name: [
						action.result.data.partner_1.name,
						action.result.data.partner_2.name,
					],
					height: [
						action.result.data.partner_1.height,
						action.result.data.partner_2.height,
					],
					figure: [
						action.result.data.partner_1.figure,
						action.result.data.partner_2.figure,
					],
					zodiak: [
						action.result.data.partner_1.zodiak,
						action.result.data.partner_2.zodiak,
					],
					hair: [
						action.result.data.partner_1.hair,
						action.result.data.partner_2.hair,
					],
					alko: [
						action.result.data.partner_1.alko,
						action.result.data.partner_2.alko,
					],
					smoke: [
						action.result.data.partner_1.smoke,
						action.result.data.partner_2.smoke,
					],
					education: [
						action.result.data.partner_1.education,
						action.result.data.partner_2.education,
					],
			  }
			: {}),
		...(action.result.data.sex !== "couple"
			? {
					birthday: [action.result.data.user_data.birthday],
					sex: [action.result.data.sex],
					name: [action.result.data.user_data.name],
					height: [action.result.data.user_data.height],
					figure: [action.result.data.user_data.figure],
					zodiak: [action.result.data.user_data.zodiak],
					hair: [action.result.data.user_data.hair],
					alko: [action.result.data.user_data.alko],
					smoke: [action.result.data.user_data.smoke],
					education: [action.result.data.user_data.education],
			  }
			: {}),
	};
	return {
		id: action.result.data.id,
		acceptedFields: {
			city: action.result.data?.status?.accepted?.city ?? true,
			description:
				action.result.data?.status?.accepted?.description ?? true,
			name: action.result.data?.status?.accepted?.name ?? true,
			nameCouple:
				action.result.data?.status?.accepted?.name_couple ?? true,
		},
		awaitAccept: action.result.data?.status?.await_accept ?? false,
		activityToken: action.result.data.activity_token,
		activityUrl: action.result.data.activity_url,
		frozen: action.result.data.frozen,
		avatar: action.result.data.avatar,
		login: action.result.data.login,
		status_vip: action.result.data.status_vip,
		online: action.result.data.online,
		profileStatus: action.result.data.profile_status,
		privateData: action.result.data.private_data,
		city: action.result.data.city_id || action.result.data.city,
		region: action.result.data.region_id,
		country: action.result.data.country,
		relationship: action.result.data.relationship,
		searchSex: action.result.data?.search_sex
			? Object.keys(action.result.data.search_sex)
					.filter((item) => action.result.data.search_sex[item])
					.sort(searchSexCustomSort)
			: [],
		searchAge: [
			action.result.data?.search_age?.from ?? null,
			action.result.data?.search_age?.to ?? null,
		],
		searchFor: Object.keys(action.result.data.search_for_new).filter(
			(item) => {
				return action.result.data.search_for_new[item];
			}
		),
		showStatusVip: action.result.data?.settings.show_status_vip ?? false,
		sound_enable: action.result.data?.sound_enable ?? false,
		sex: action.result.data?.sex ?? null,
		cityName: action.result.data.city,
		regionName: action.result.data.region,
		kids: action.result.data.kids,
		partners,
		created: action.result.data.create_full,
		languages: action.result.data?.languages ?? [],
		user_language: action.result.data?.user_language ?? null,
		preferences: action.result.data?.preferences ?? [],
		description: {
			parsed: action.result.data.description,
			plain: action.result.data.description_raw,
		},
		vip_left: {
			date: action.result.data.statistics.vip_left?.date ?? null,
			time: action.result.data.statistics.vip_left?.time ?? null,
		},
		post_left: {
			date: action.result.data.statistics.post_left.date,
			limit: action.result.data.statistics.post_left.limit,
			max: action.result.data.statistics.post_left.max,
		},
		complete: {
			...action.result.data.complete,
		},
	};
};

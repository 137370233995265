import { useEffect, useState, forwardRef, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconContainer = styled(
	({
		color,
		withoutSvgWidth,
		width,
		height,
		verticalAlign,
		spin,
		mirror,
		alwaysLight,
		isActive,
		...rest
	}) => <span {...rest} />
)`
	display: inline-block;

	${({ color }) =>
		color &&
		css`
			color: ${({ theme: { colors } }) => colors[color]};
		`}

	svg {
		display: inline-block;
		vertical-align: -0.125em;
		height: 1em;
		width: ${({ withoutSvgWidth }) => (!withoutSvgWidth ? "1em" : "auto")};

		${({ width, withoutSvgWidth }) =>
			!withoutSvgWidth
				? css`
						width: ${width ? `${width}` : "1em"};
				  `
				: css`
						height: "auto";
				  `}

		${({ height }) =>
			height
				? css`
						height: ${height};
				  `
				: css`
						height: 1em;
				  `}

		${({ verticalAlign }) =>
			verticalAlign &&
			css`
				vertical-align: ${verticalAlign};
			`}

		${({ spin }) =>
			spin &&
			css`
				animation: ${rotate} 2s linear infinite;
			`}

		${({ color }) =>
			color &&
			css`
				color: ${({ theme: { colors } }) => colors[color]}!important;
			`}

		${({ mirror }) =>
			mirror &&
			css`
				transform: rotateY(180deg);
			`}

		${({ alwaysLight }) =>
			css`
				color: ${({ theme: { colors, colorsDefaults } }) =>
					alwaysLight ? colorsDefaults.black : colors.black};
			`}

			${({ isActive }) =>
			isActive &&
			css`
				color: ${({ theme: { colors, isDarkMode, utils } }) =>
					isDarkMode
						? colors.primaryLight
						: utils.lighten(colors.primary, 2)};};
			`}
	}
`;

const TYPE = {
	fas: "solid",
	far: "regular",
	fal: "light",
};

const Icon = forwardRef(
	(
		{
			icon: iconName,
			svgIconComponent,
			children,
			type = "fas",
			size,
			spin,
			mirror,
			fill,
			alwaysLight,
			color,
			verticalAlign,
			idAttribute,
			...props
		},
		ref
	) => {
		const mounted = useRef(false);
		const [iconRaw, setIconRaw] = useState(null);
		const finalIcon = iconName || children;
		const finalType = TYPE[type] || TYPE.fas;

		useEffect(() => {
			mounted.current = true;

			if (finalIcon) {
				try {
					import(
						`!!raw-loader!resources/icons/${finalType}/${finalIcon}.svg`
					).then(({ default: raw }) => {
						if (mounted.current) {
							setIconRaw(raw);
						}
					});
				} catch {
					if (mounted.current) {
						setIconRaw(null);
					}
				}
			}

			return () => {
				mounted.current = false;
			};
		}, [finalIcon, finalType]);

		const StaticImportSvgIcon = ({ svgIconComponent }) => {
			const CreateSvgIcon = svgIconComponent;
			return <CreateSvgIcon />;
		};

		return (
			<IconContainer
				color={color}
				className="icon"
				spin={spin}
				mirror={mirror}
				alwaysLight={alwaysLight}
				verticalAlign={verticalAlign}
				idattribute={idAttribute}
				{...props}
			>
				{svgIconComponent ? (
					<StaticImportSvgIcon svgIconComponent={svgIconComponent} />
				) : (
					<SVG src={iconRaw} ref={ref} fill={fill} />
				)}
			</IconContainer>
		);
	}
);

Icon.propTypes = {
	icon: PropTypes.string,
	type: PropTypes.oneOf(["fas", "far", "fal"]),
	mirror: PropTypes.bool,
};

Icon.defaultProps = {
	icon: null,
	type: "fas",
	mirror: false,
};

Icon.displayName = "Icon";

Icon.Container = IconContainer;

export default Icon;

import { css } from "styled-components";
import PropTypes from "prop-types";

const resolveProps = (props, ...mixins) => {
	const newProps = { ...props };
	mixins.forEach((mixin) => {
		const styleModifiers = mixin.propTypesDefinition
			? Object.keys(mixin.propTypesDefinition)
			: [];
		styleModifiers.forEach(
			(propName) =>
				newProps[propName] !== undefined && delete newProps[propName]
		);
	});
	return newProps;
};

const offsetWidth = 0.25;
const marginable = {
	css: css`
		${({ p }) =>
			p !== undefined &&
			css`
				padding: ${p * offsetWidth}rem;
			`}
		${({ px }) =>
			px !== undefined &&
			css`
				padding-left: ${px * offsetWidth}rem;
				padding-right: ${px * offsetWidth}rem;
			`}
		${({ py }) =>
			py !== undefined &&
			css`
				padding-top: ${py * offsetWidth}rem;
				padding-bottom: ${py * offsetWidth}rem;
			`}
		${({ pt }) =>
			pt !== undefined &&
			css`
				padding-top: ${pt * offsetWidth}rem;
			`}
		${({ pb }) =>
			pb !== undefined &&
			css`
				padding-bottom: ${pb * offsetWidth}rem;
			`}
		${({ pl }) =>
			pl !== undefined &&
			css`
				padding-left: ${pl * offsetWidth}rem;
			`}
		${({ pr }) =>
			pr !== undefined &&
			css`
				padding-right: ${pr * offsetWidth}rem;
			`}

		${({ m }) =>
			m !== undefined &&
			css`
				margin: ${m * offsetWidth}rem;
			`}
		${({ mx }) =>
			mx !== undefined &&
			css`
				margin-left: ${mx * offsetWidth}rem;
				margin-right: ${mx * offsetWidth}rem;
			`}
		${({ my }) =>
			my !== undefined &&
			css`
				margin-top: ${my * offsetWidth}rem;
				margin-bottom: ${my * offsetWidth}rem;
			`}
		${({ mt }) =>
			mt !== undefined &&
			css`
				margin-top: ${mt * offsetWidth}rem;
			`}
		${({ mb }) =>
			mb !== undefined &&
			css`
				margin-bottom: ${mb * offsetWidth}rem;
			`}
		${({ ml }) =>
			ml !== undefined &&
			css`
				margin-left: ${ml * offsetWidth}rem;
			`}
		${({ mr }) =>
			mr !== undefined &&
			css`
				margin-right: ${mr * offsetWidth}rem;
			`}

		${({ m0auto }) =>
			m0auto !== undefined &&
			css`
				margin: 0 auto;
			`}

		${({ mtauto }) =>
			mtauto !== undefined &&
			css`
				margin-top: auto;
			`}
	`,
	propTypesDefinition: {
		p: PropTypes.number,
		px: PropTypes.number,
		py: PropTypes.number,
		pt: PropTypes.number,
		pb: PropTypes.number,
		pl: PropTypes.number,
		pr: PropTypes.number,
		m: PropTypes.number,
		mx: PropTypes.number,
		my: PropTypes.number,
		mt: PropTypes.number,
		mb: PropTypes.number,
		ml: PropTypes.number,
		mr: PropTypes.number,
		m0auto: PropTypes.bool,
	},
	defaultPropsDefinition: {},
};

const fontable = {
	css: css`
		${({ textBreak }) =>
			textBreak &&
			css`
				word-break: break-word;
			`}

		${({ textNowrap }) =>
			textNowrap &&
			css`
				white-space: nowrap;
			`}
		${({ muted }) =>
			muted &&
			css`
				opacity: 0.75;
			`}

			${({ supermuted }) =>
			supermuted &&
			css`
				opacity: 0.5;
			`}
		${({ small }) =>
			small &&
			css`
				font-size: 0.8rem;
			`}
		${({ bold }) => {
			return css`
				font-weight: ${bold === true ? "bold" : bold};
			`;
		}}
		${({ theme: { colors, utils }, color, colorShade }) => {
			let finalColor = colors[color];
			if (colorShade) {
				if (colorShade < 0) {
					finalColor = utils.darken(colors[color], colorShade * -1);
				} else {
					finalColor = utils.lighten(colors[color], colorShade);
				}
			}
			return css`
				color: ${finalColor};

				svg {
					color: ${finalColor};
				}
			`;
		}}
		${({ size }) => {
			return css`
				font-size: ${size};
			`;
		}}
		${({ line }) => {
			return css`
				line-height: ${line};
			`;
		}}
		${({ textCenter }) =>
			textCenter &&
			css`
				text-align: center;
			`}
		${({ italicFontStyle }) =>
			italicFontStyle &&
			css`
				font-style: italic;
			`}

			${({ underline }) =>
			underline &&
			css`
				&:hover {
					text-decoration: underline;
				}
			`}
	`,
	propTypesDefinition: {
		muted: PropTypes.bool,
		small: PropTypes.bool,
		bold: PropTypes.bool,
		color: PropTypes.string,
		colorShade: PropTypes.number,
		size: PropTypes.string,
		line: PropTypes.string,
		textCenter: PropTypes.bool,
		textNowrap: PropTypes.bool,
		textBreak: PropTypes.bool,
		italicFontStyle: PropTypes.bool,
	},
	defaultPropsDefinition: {
		colorShade: 0,
		textBreak: false,
	},
};

export { resolveProps, marginable, fontable };

/* eslint-disable camelcase */
import defaults from "../defaults";

const GET_USERS = "mainPages/GET_USERS";
const GET_USERS_SUCCESS = "mainPages/GET_USERS_SUCCESS";
const GET_USERS_FAIL = "mainPages/GET_USERS_FAIL";

const GET_RANKINGS_USERS = "mainPages/GET_RANKINGS_USERS";
const GET_RANKINGS_USERS_SUCCESS = "mainPages/GET_RANKINGS_USERS_SUCCESS";
const GET_RANKINGS_USERS_FAIL = "mainPages/GET_RANKINGS_USERS_FAIL";

const GET_GALLERY = "mainPages/GET_GALLERY";
const GET_GALLERY_SUCCESS = "mainPages/GET_GALLERY_SUCCESS";
const GET_GALLERY_FAIL = "mainPages/GET_GALLERY_FAIL";

const GET_CAMERAS = "mainPages/GET_CAMERAS";
const GET_CAMERAS_SUCCESS = "mainPages/GET_CAMERAS_SUCCESS";
const GET_CAMERAS_FAIL = "mainPages/GET_CAMERAS_FAIL";

const GET_GROUPS = "mainPages/GET_GROUPS";
const GET_GROUPS_SUCCESS = "mainPages/GET_GROUPS_SUCCESS";
const GET_GROUPS_FAIL = "mainPages/GET_GROUPS_FAIL";

const GET_EVENTS = "mainPages/GET_EVENTS";
const GET_EVENTS_SUCCESS = "mainPages/GET_EVENTS_SUCCESS";
const GET_EVENTS_FAIL = "mainPages/GET_EVENTS_FAIL";

const GET_FINISHED_EVENTS = "mainPages/GET_FINISHED_EVENTS";
const GET_FINISHED_EVENTS_SUCCESS = "mainPages/GET_FINISHED_EVENTS_SUCCESS";
const GET_FINISHED_EVENTS_FAIL = "mainPages/GET_FINISHED_EVENTS_FAIL";

const POST_SUPPORT_MESSAGE = "mainPages/POST_SUPPORT_MESSAGE";
const POST_SUPPORT_MESSAGE_SUCCESS = "mainPages/POST_SUPPORT_MESSAGE_SUCCESS";
const POST_SUPPORT_MESSAGE_FAIL = "mainPages/POST_SUPPORT_MESSAGE_FAIL";

const GET_SUPPORT_PAGE = "pages/GET_SUPPORT_PAGE";
const GET_SUPPORT_PAGE_SUCCESS = "pages/GET_SUPPORT_PAGE_SUCCESS";
const GET_SUPPORT_PAGE_FAIL = "pages/GET_SUPPORT_PAGE_FAIL";

const GET_PRIVACY_PAGE = "pages/GET_PRIVACY_PAGE";
const GET_PRIVACY_PAGE_SUCCESS = "pages/GET_PRIVACY_PAGE_SUCCESS";
const GET_PRIVACY_PAGE_FAIL = "pages/GET_PRIVACY_PAGE_FAIL";

const GET_TERMS_PAGE = "pages/GET_TERMS_PAGE";
const GET_TERMS_PAGE_SUCCESS = "pages/GET_TERMS_PAGE_SUCCESS";
const GET_TERMS_PAGE_FAIL = "pages/GET_TERMS_PAGE_FAIL";

const GET_APP_PAGE = "pages/GET_APP_PAGE";
const GET_APP_PAGE_SUCCESS = "pages/GET_APP_PAGE_SUCCESS";
const GET_APP_PAGE_FAIL = "pages/GET_APP_PAGE_FAIL";

const GET_NEWS_PAGE = "pages/GET_NEWS_PAGE";
const GET_NEWS_PAGE_SUCCESS = "pages/GET_NEWS_PAGE_SUCCESS";
const GET_NEWS_PAGE_FAIL = "pages/GET_NEWS_PAGE_FAIL";

const GET_SINGLE_NEWS_PAGE = "pages/GET_SINGLE_NEWS_PAGE";
const GET_SINGLE_NEWS_PAGE_SUCCESS = "pages/GET_SINGLE_NEWS_PAGE_SUCCESS";
const GET_SINGLE_NEWS_PAGE_FAIL = "pages/GE_SINGLET_NEWS_PAGE_FAIL";

const GET_HOME_PAGE = "pages/GET_HOME_PAGE";
const GET_HOME_PAGE_SUCCESS = "pages/GET_HOME_PAGE_SUCCESS";
const GET_HOME_PAGE_FAIL = "pages/GET_HOME_PAGE_FAIL";

const GET_LANDING = "pages/GET_LANDING";
const GET_LANDING_SUCCESS = "pages/GET_LANDING_SUCCESS";
const GET_LANDING_FAIL = "pages/GET_LANDING_FAIL";

const GET_LOCATION_SEO = "pages/GET_LOCATION_SEO";
const GET_LOCATION_SEO_SUCCESS = "pages/GET_LOCATION_SEO_SUCCESS";
const GET_LOCATION_SEO_FAIL = "pages/GET_LOCATION_SEO_FAIL";

const GET_PREFERENCES_SEO = "pages/GET_PREFERENCES_SEO";
const GET_PREFERENCES_SEO_SUCCESS = "pages/GET_PREFERENCES_SEO_SUCCESS";
const GET_PREFERENCES_SEO_FAIL = "pages/GET_PREFERENCES_SEO_FAIL";

const GET_POSTS = "pages/GET_POSTS";
const GET_POSTS_SUCCESS = "pages/GET_POSTS_SUCCESS";
const GET_POSTS_FAIL = "pages/GET_POSTS_FAIL";

const initialState = defaults.mainPages;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_USERS:
			return {
				...state,
				users: {
					...(action.isFirst ? initialState.users : state.users),
					loading: true,
					loaded: false,
					success: false,
					error: false,
					fromServer: false,
				},
			};
		case GET_USERS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newData = action.infinityScroll
				? [...state.users.data, ...(action.result.data?.users ?? [])]
				: action.result.data?.users ?? [];

			return {
				...state,
				users: {
					...state.users,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: action.infinityScroll
						? newData.filter((val, id) => {
								return (
									id ===
									newData.findIndex((el) => el.id === val.id)
								);
						  })
						: newData,
					location: {
						country: action.result.data?.location.country || null,
						countryId:
							action.result.data?.location_search?.country?.id ||
							null,
						region: action.result.data?.location.region || null,
						city: action.result.data?.location.city || null,
					},
					location_search: {
						country:
							action.result.data?.location_search.country || null,
						countries:
							action.result.data?.location_search.countries ||
							null,
						region:
							action.result.data?.location_search.region || null,
						regions:
							action.result.data?.location_search.regions || null,
						city: action.result.data?.location_search.city || null,
						cities:
							action.result.data?.location_search.cities || null,
					},
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					findnumber: action.result.data?.total ?? 0,
					fromServer: action.fromServer,
					locationMd5Hash: action.locationMd5Hash,
				},
			};
		case GET_USERS_FAIL:
			return {
				...state,
				users: {
					...state.users,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		case GET_RANKINGS_USERS:
			return {
				...state,
				rankings: {
					...(action.isFirst
						? initialState.rankings
						: state.rankings),
					loading: true,
				},
			};
		case GET_RANKINGS_USERS_SUCCESS:
			return {
				...state,
				rankings: {
					...state.rankings,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: action.result.data?.users ?? [],
					location: {
						country: action.result.data?.location?.country,
						countryId:
							action.result.data?.location?.country_id || null,
						countryWithRegions:
							action.result.data?.location?.with_regions,
						region: action.result.data?.location?.region,
					},
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					fromServer: action.fromServer,
					locationMd5Hash: action.locationMd5Hash,
				},
			};
		case GET_RANKINGS_USERS_FAIL:
			return {
				...state,
				rankings: {
					...state.rankings,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// ** GET GALLERY
		case GET_GALLERY:
			return {
				...state,
				gallery: {
					...state.gallery,
					location: {
						city: null,
						region: null,
						country: null,
					},
					loading: true,
				},
			};
		case GET_GALLERY_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataGallery = action.infinityScroll
				? [...state.gallery.data, ...(action.result.data?.media ?? [])]
				: action.result.data?.media ?? [];

			return {
				...state,
				gallery: {
					...state.gallery,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: action.infinityScroll
						? newDataGallery.filter((val, id) => {
								return (
									id ===
									newDataGallery.findIndex(
										(el) => el.id === val.id
									)
								);
						  })
						: newDataGallery,
					location: {
						city: action.result.data?.location?.city || "",
						region: action.result.data?.location?.region || "",
						country: action.result.data?.location?.country || "",
					},
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					minDates: action.result.data?.min_dates ?? [],
					fromServer: action.fromServer,
					locationMd5Hash: action.locationMd5Hash,
				},
			};
		case GET_GALLERY_FAIL:
			return {
				...state,
				gallery: {
					...state.gallery,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		case GET_CAMERAS:
			return {
				...state,
				cameras: {
					...state.cameras,
					loading: true,
				},
			};
		case GET_CAMERAS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataCameras = action.infinityScroll
				? [
						...state.cameras.data,
						...(action.result.data?.cameras ?? []),
				  ]
				: action.result.data?.cameras ?? [];
			return {
				...state,
				cameras: {
					...state.cameras,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: newDataCameras,
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					fromServer: action.fromServer,
				},
			};
		case GET_CAMERAS_FAIL:
			return {
				...state,
				cameras: {
					...state.cameras,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		case GET_GROUPS:
			return {
				...state,
				groups: {
					...state.groups,
					loading: true,
				},
			};
		case GET_GROUPS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataGroups = action.infinityScroll
				? [...state.groups.data, ...(action.result.data?.groups ?? [])]
				: action.result.data?.groups ?? [];
			return {
				...state,
				groups: {
					...state.groups,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: action.infinityScroll
						? newDataGroups.filter((val, id) => {
								return (
									id ===
									newDataGroups.findIndex(
										(el) => el.id === val.id
									)
								);
						  })
						: newDataGroups,
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					findnumber: action.result.data?.total ?? 0,
					requestHash: action.requestHash,
				},
			};
		case GET_GROUPS_FAIL:
			return {
				...state,
				groups: {
					...state.groups,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		case GET_EVENTS:
			return {
				...state,
				events: {
					...(action.isFirst ? initialState.events : state.events),
					loading: true,
				},
			};
		case GET_EVENTS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataEvents = action.infinityScroll
				? [...state.events.data, ...(action.result.data?.events ?? [])]
				: action.result.data?.events ?? [];
			return {
				...state,
				events: {
					...state.events,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: action.infinityScroll
						? newDataEvents.filter((val, id) => {
								return (
									id ===
									newDataEvents.findIndex(
										(el) => el.id === val.id
									)
								);
						  })
						: newDataEvents,
					location: {
						country: action.result.data?.location.country || null,
						countryId:
							action.result.data?.location_search?.country?.id ||
							null,
						region: action.result.data?.location.region || null,
						city: action.result.data?.location.city || null,
					},
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					findnumber: action.result.data?.total ?? 0,
					fromServer: action.fromServer,
					requestHash: action.requestHash,
				},
			};
		case GET_EVENTS_FAIL:
			return {
				...state,
				events: {
					...state.events,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		case GET_FINISHED_EVENTS:
			return {
				...state,
				events: {
					...state.events,
					finished: {
						...state.events.finished,
						loading: true,
					},
				},
			};
		case GET_FINISHED_EVENTS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataFinishedEvents = action.infinityScroll
				? [
						...state.events.finished.data,
						...(action.result.data?.events ?? []),
				  ]
				: action.result.data?.events ?? [];
			return {
				...state,
				events: {
					...state.events,
					finished: {
						...state.events.finished,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						data: action.infinityScroll
							? newDataFinishedEvents.filter((val, id) => {
									return (
										id ===
										newDataFinishedEvents.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataFinishedEvents,
						pagination: {
							currentpage:
								action.result.data?.pagination.currentpage ?? 1,
							totalpages:
								action.result.data?.pagination.totalpages ?? 1,
						},
						requestHash: action.requestHash,
					},
				},
			};
		case GET_FINISHED_EVENTS_FAIL:
			return {
				...state,
				events: {
					...state.events,
					finished: {
						...state.events.finished,
						loading: false,
						loaded: true,
						success: false,
						error: true,
					},
				},
			};
		case POST_SUPPORT_MESSAGE:
			return {
				...state,
				contact: {
					...state.contact,
					loading: true,
					success: false,
					error: false,
					loaded: false,
				},
			};
		case POST_SUPPORT_MESSAGE_SUCCESS:
			return {
				...state,
				contact: {
					...state.contact,
					loading: false,
					success: true,
					loaded: true,
				},
			};
		case POST_SUPPORT_MESSAGE_FAIL:
			return {
				...state,
				contact: {
					...state.contact,
					loading: false,
					success: false,
					error: true,
					loaded: true,
				},
			};
		case GET_SUPPORT_PAGE: {
			return {
				...state,
				support: {
					...state.support,
					loading: true,
				},
			};
		}
		case GET_SUPPORT_PAGE_SUCCESS: {
			return {
				...state,
				support: {
					...state.support,
					loading: false,
					loaded: true,
					lang: action.language,
					title: action.result.data[0]?.value,
					data: action.result.data?.slice(
						1,
						action.result.data?.length
					),
				},
			};
		}
		case GET_SUPPORT_PAGE_FAIL: {
			return {
				...state,
				support: {
					...initialState.support,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		}
		case GET_PRIVACY_PAGE: {
			return {
				...state,
				privacy: {
					...state.privacy,
					loading: true,
				},
			};
		}
		case GET_PRIVACY_PAGE_SUCCESS: {
			return {
				...state,
				privacy: {
					...state.privacy,
					loading: false,
					loaded: true,
					lang: action.language,
					title: action.result.data[0]?.value,
					subtitle: action.result.data
						?.slice(1, 5)
						.reduce((newText = "", item) => {
							if (typeof newText === "object") {
								return newText.value + ` ${item.value}`;
							}
							return newText + ` ${item.value}`;
						}),
					data: action.result.data[4]?.child,
					date: [action.result.data[action.result.data?.length - 1]],
				},
			};
		}
		case GET_PRIVACY_PAGE_FAIL: {
			return {
				...state,
				privacy: {
					...initialState.privacy,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		}
		case GET_TERMS_PAGE: {
			return {
				...state,
				terms: {
					...state.terms,
					loading: true,
				},
			};
		}
		case GET_TERMS_PAGE_SUCCESS: {
			return {
				...state,
				terms: {
					...state.terms,
					loading: false,
					loaded: true,
					lang: action.language,
					title: action.result.data[0]?.value,
					data: action.result.data[0]?.child,
					date: [action.result.data[action.result.data?.length - 1]],
				},
			};
		}
		case GET_TERMS_PAGE_FAIL: {
			return {
				...state,
				terms: {
					...initialState.terms,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		}
		case GET_POSTS:
			return {
				...state,
				posts: {
					...state.posts,
					location: {
						city: null,
						region: null,
						country: null,
					},
					loading: true,
				},
			};
		case GET_POSTS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const newDataPosts = action.infinityScroll
				? [...state.posts.data, ...(action.result.data?.posts ?? [])]
				: action.result.data?.posts ?? [];

			return {
				...state,
				posts: {
					...state.posts,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					location: {
						city: action.result.data?.location?.city || "",
						region: action.result.data?.location?.region || "",
						country: action.result.data?.location?.country || "",
					},
					data: action.infinityScroll
						? newDataPosts.filter((val, id) => {
								return (
									id ===
									newDataPosts.findIndex(
										(el) => el.id === val.id
									)
								);
						  })
						: newDataPosts,
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
					minDates: action.result.data?.min_dates ?? [],
					fromServer: action.fromServer,
					locationMd5Hash: action.locationMd5Hash,
				},
			};

		case GET_POSTS_FAIL:
			return {
				...state,
				posts: {
					...state.posts,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// ** GET NEWS PAGE DATA
		case GET_NEWS_PAGE:
			return {
				...state,
				news: {
					...state.news,
					loading: true,
				},
			};
		case GET_NEWS_PAGE_SUCCESS:
			return {
				...state,
				news: {
					...state.news,
					loading: false,
					hash: action.hash,
					data: action.result.data?.news ?? [],
					pagination: {
						currentpage:
							action.result.data?.pagination.currentpage ?? 1,
						totalpages:
							action.result.data?.pagination.totalpages ?? 1,
					},
				},
			};
		case GET_NEWS_PAGE_FAIL:
			return {
				...state,
				news: {
					...state.news,
					loading: false,
				},
			};
		// ** GET SINGLE NEWS PAGE DATA
		case GET_SINGLE_NEWS_PAGE:
			return {
				...state,
				singleNews: {
					...state.singleNews,
					loading: true,
					data: {},
				},
			};
		case GET_SINGLE_NEWS_PAGE_SUCCESS:
			return {
				...state,
				singleNews: {
					...state.singleNews,
					loading: false,
					error: false,
					lang: action.language,
					hash: action.hash,
					data: action.result.data ?? {},
				},
			};
		case GET_SINGLE_NEWS_PAGE_FAIL:
			return {
				...state,
				singleNews: {
					...state.singleNews,
					loading: false,
					error: true,
				},
			};
		// ** GET APP PAGE DATA
		case GET_APP_PAGE:
			return {
				...state,
				app: {
					...state.app,
					loading: true,
				},
			};
		case GET_APP_PAGE_SUCCESS:
			return {
				...state,
				app: {
					...state.app,
					loading: false,
					loaded: true,
					error: false,
					data: action.result.data ?? {},
				},
			};
		case GET_APP_PAGE_FAIL:
			return {
				...state,
				app: {
					...state.app,
					loading: false,
					loaded: true,
					error: true,
				},
			};
		// ** GET HOME PAGE DATA
		case GET_HOME_PAGE:
			return {
				...state,
				home: {
					...state.home,
					loading: true,
				},
			};
		case GET_HOME_PAGE_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const {
				cameras,
				events,
				groups,
				latest,
				news,
				notifications,
				photos,
				posts,
				ranking,
				videos,
				media,
			} = action.result?.data || {};
			return {
				...state,
				home: {
					...state.home,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					lang: action.language,
					data: {
						...state.home.data,
						cameras: cameras?.response?.cameras || [],
						events: events?.response?.events || [],
						groups: groups?.response?.groups || [],
						latest: latest?.response || [],
						news: news?.response?.news || [],
						notifications:
							notifications?.response?.notifications || [],
						photos: photos?.response?.photos || [],
						posts: posts?.response?.posts || [],
						ranking: ranking?.response || [],
						videos: videos?.response?.videos || [],
						gallery: media?.response?.media || [],
					},
				},
			};
		case GET_HOME_PAGE_FAIL:
			return {
				...state,
				home: {
					...state.home,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// ** GET_LOCATION_SEO
		case GET_LOCATION_SEO:
			return {
				...state,
				locationSeo: {
					...state.locationSeo,
					loading: true,
				},
			};
		case GET_LOCATION_SEO_SUCCESS:
			return {
				...state,
				locationSeo: {
					...state.locationSeo,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: {
						...state.locationSeo.data,
						locations: action.result.data?.data || [],
						total: action.result.data?.total,
					},
				},
			};
		case GET_LOCATION_SEO_FAIL:
			return {
				...state,
				locationSeo: {
					...state.locationSeo,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// ** GET_PREFERENCES_SEO
		case GET_PREFERENCES_SEO:
			return {
				...state,
				preferencesSeo: {
					...state.preferencesSeo,
					loading: true,
				},
			};
		case GET_PREFERENCES_SEO_SUCCESS:
			return {
				...state,
				preferencesSeo: {
					...state.preferencesSeo,
					loading: false,
					loaded: true,
					success: true,
					error: false,
					data: {
						...state.preferencesSeo.data,
						preferences: action.result.data?.data || [],
						total: action.result.data?.total,
					},
				},
			};
		case GET_PREFERENCES_SEO_FAIL:
			return {
				...state,
				preferencesSeo: {
					...state.preferencesSeo,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		// ** GET LANDING
		case GET_LANDING:
			return {
				...state,
				landing: {
					...state.landing,
					loading: true,
				},
			};
		case GET_LANDING_SUCCESS:
			if (action.language === "pl") {
				return {
					...state,
					landing: {
						...state.landing,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						lang: action.language,
						data: {
							...state.landing.data,
							images: action.result?.data?.images?.response || [],
							locationsSeo:
								action.result?.data?.seo?.response.location ||
								[],
							preferencesSeo:
								action.result?.data.seo?.response.preferences ||
								[],
							news: action.result?.data.news?.response.news
								? action.result?.data.news?.response.news.slice(
										0,
										3
								  )
								: [],
						},
					},
				};
			} else {
				return {
					...state,
					landing: {
						...state.landing,
						loading: false,
						loaded: true,
						success: true,
						error: false,
						lang: action.language,
						data: {
							...state.landing.data,
							images: action.result?.data || [],
						},
					},
				};
			}

		case GET_LANDING_FAIL:
			return {
				...state,
				landing: {
					...state.landing,
					loading: false,
					loaded: true,
					success: false,
					error: true,
				},
			};
		default:
			return state;
	}
}

/**
 * @api GET advencedSearchPost
 * @param {Number} photoId id zdjecia
 * @param {integer|null} page
 * @param {string|null} sort 'active','added','popular',
 * @param {integer|null} sex
 * @param {integer|null} age_min
 * @param {integer|null} age_max
 * @param {integer|null} sex_couple_1
 * @param {integer|null} sex_couple_2
 * @param {string|null} country
 * @param {integer|null} region
 * @param {string|integer|null} city
 * @param {integer|null} distance
 * @param {string|null} s_sex
 * @param {integer|array[integer]|null} for
 * @param {integer|null} only
 * @param {integer|null} s_age
 * @param {string|null} language
 * @param {integer|null} height_min
 * @param {integer|null} height_max
 * @param {integer|null} figure
 * @param {integer|null} zodiac
 * @param {integer|null} hair
 * @param {integer|null} relationship
 * @param {integer|null} kids
 * @param {integer|null} drinking
 * @param {integer|null} smoking
 * @param {integer|null} education
 * @param {integer|null} preference
 * @param {string|null} name
 */

export function getUsers(
	{
		page = 1,
		sort = null,
		age_min = null,
		age_max = null,
		sex = null,
		sex_couple_1 = null,
		sex_couple_2 = null,
		country = null,
		region = null,
		city = null,
		distance = null,
		s_sex = null,
		only = null,
		s_age = null,
		s_for = null,
		language = null,
		height_min = null,
		height_max = null,
		figure = null,
		zodiac = null,
		hair = null,
		relationship = null,
		kids = null,
		drinking = null,
		smoking = null,
		education = null,
		preference = null,
		name = null,
		view = null,
	},
	isAuth = true,
	fromServer = false,
	isFirst = false,
	useUrl = false,
	locationMd5Hash = "",
	infinityScroll = false
) {
	return {
		types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL],
		promise: (client) =>
			client.post(
				`/datezone/search${
					useUrl ? "-url" : isAuth ? "" : "/unauthorized"
				}`,
				{
					page,
					sort,
					age_min,
					age_max,
					sex,
					sex_couple_1,
					sex_couple_2,
					country,
					region,
					city,
					distance,
					only,
					s_sex,
					s_age,
					for: s_for,
					language,
					height_min,
					height_max,
					figure,
					zodiac,
					hair,
					relationship,
					kids,
					drinking,
					smoking,
					education,
					preference,
					name,
					view_type: view,
				},
				{
					headers: {
						Accept: "application/api.1.1+json",
					},
				}
			),
		fromServer,
		isFirst,
		locationMd5Hash,
		infinityScroll,
	};
}

export function getRankingsUsers(
	{
		page = 1,
		period = null,
		sex = null,
		age = null,
		content = null,
		country = null,
		region = null,
	},
	fromServer = false,
	isFirst = false,
	useUrl = false,
	useUser = null,
	locationMd5Hash = "",
	errorAction = null
) {
	return {
		types: [
			GET_RANKINGS_USERS,
			GET_RANKINGS_USERS_SUCCESS,
			GET_RANKINGS_USERS_FAIL,
		],
		promise: (client) =>
			client
				.get(`/datezone/ranking${useUrl ? "-url" : ""}`, {
					params: {
						period,
						sex,
						age,
						content,
						country,
						region,
						page,
						useUser: useUser ? useUser : null,
					},
				})
				.catch((err) => {
					if (err) {
						if (typeof errorAction === "function") {
							errorAction();
						}
					}
				}),
		fromServer,
		isFirst,
		useUrl,
		locationMd5Hash,
		noToast: true,
	};
}

export function getGallery(
	{
		page = 1,
		sex = null,
		year = null,
		month = null,
		day = null,
		city = "any",
		region = "any",
		country = "all",
		distance = 50,
		format = "short",
		type = "any",
	},
	isAuth,
	fromServer = false,
	infinityScroll = false,
	locationMd5Hash = ""
) {
	return {
		types: [GET_GALLERY, GET_GALLERY_SUCCESS, GET_GALLERY_FAIL],
		promise: (client) =>
			client.get("/media/all", {
				params: {
					page,
					sex,
					year,
					month,
					day,
					city,
					region,
					country,
					distance,
					format,
					type,
				},
			}),
		fromServer,
		infinityScroll,
		locationMd5Hash,
	};
}

export function getCameras(
	{ page = 1, sex = null },
	fromServer = false,
	infinityScroll = false
) {
	return {
		types: [GET_CAMERAS, GET_CAMERAS_SUCCESS, GET_CAMERAS_FAIL],
		promise: (client) =>
			client.get("/camera", {
				params: { page, sex },
			}),
		fromServer,
		infinityScroll,
	};
}

/**
 * @api POST advencedSearchGroups
 * @param {integer|null} page
 * @param {string|null} sort 'popular', 'newest'
 * @param {string|null} name
 */
export function getSearchGroups(
	{ page = 1, sort = "", name = null },
	requestHash = "",
	infinityScroll = false
) {
	return {
		types: [GET_GROUPS, GET_GROUPS_SUCCESS, GET_GROUPS_FAIL],
		promise: (client) =>
			client.post(
				`/group/search`,
				{
					page,
					sort,
					name,
				},
				{
					headers: {
						Accept: "application/api.1.1+json",
					},
				}
			),
		requestHash,
		infinityScroll,
	};
}

/**
 * @api POST advencedSearchEvents
 * @param {integer|null} page
 * @param {string|null} sort 'popular', 'newest'
 * @param {string|null} country
 * @param {integer|null} region
 * @param {string|integer|null} city
 * @param {integer|null} distance
 * @param {date|null} dateStart
 * @param {date|null} dateEnd
 * @param {string|null} name
 */
export function getSearchEvents(
	{
		page = 1,
		sort = "",
		country = null,
		region = null,
		city = null,
		distance = null,
		dateStart = null,
		dateEnd = null,
		name = null,
	},
	fromServer = false,
	isFirst = false,
	requestHash = "",
	infinityScroll = false
) {
	return {
		types: [GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAIL],
		promise: (client) =>
			client.post(
				`/event/search`,
				{
					page,
					sort,
					country,
					region,
					city,
					distance,
					name,
					dateStart,
					dateEnd,
				},
				{
					headers: {
						Accept: "application/api.1.1+json",
					},
				}
			),
		fromServer,
		isFirst,
		requestHash,
		infinityScroll,
	};
}

export function getFinishedEvents(
	{ page = 1 },
	requestHash,
	infinityScroll = false
) {
	return {
		types: [
			GET_FINISHED_EVENTS,
			GET_FINISHED_EVENTS_SUCCESS,
			GET_FINISHED_EVENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/event/ended`, {
				params: {
					page,
				},
			}),
		requestHash,
		infinityScroll,
	};
}

export function postSupportMessage({
	token = false,
	email = "",
	message = "",
	isAndroid = false,
	version = "web",
	phoneBrand = "web",
	appVersion = "web",
	file = null,
}) {
	const finalMessage = message;
	const data = {
		email,
		message: finalMessage,
		isAndroid,
		version,
		phoneBrand,
		appVersion,
	};
	const formData = new FormData();
	formData.append("data", JSON.stringify(data));
	if (token) {
		formData.append("token", token);
	}
	if (file) {
		formData.append("file", file);
	}

	return {
		types: [
			POST_SUPPORT_MESSAGE,
			POST_SUPPORT_MESSAGE_SUCCESS,
			POST_SUPPORT_MESSAGE_FAIL,
		],
		promise: (client) =>
			!token
				? client.post("/datezone/support/unauthorized", formData)
				: file
				? client
						.get("/system/token")
						.then(({ data: { token: _uploadToken } }) => {
							formData.append("token", _uploadToken);
							return client.post("/datezone/support", formData);
						})
				: client.post("/datezone/support", formData),
	};
}

export function getSupportJson({ language }) {
	return {
		types: [
			GET_SUPPORT_PAGE,
			GET_SUPPORT_PAGE_SUCCESS,
			GET_SUPPORT_PAGE_FAIL,
		],
		promise: (client) => {
			client.modifyLanguageHeader(language);
			return client.get("/statics/support", {
				params: {
					type: "support",
				},
			});
		},
		language,
	};
}

export function getPrivacyJson({ language }) {
	return {
		types: [
			GET_PRIVACY_PAGE,
			GET_PRIVACY_PAGE_SUCCESS,
			GET_PRIVACY_PAGE_FAIL,
		],
		promise: (client) => {
			client.modifyLanguageHeader(language);
			return client.get("/statics/support", {
				params: { type: "privacy" },
			});
		},
		language,
	};
}

export function getTermsJson({ language }) {
	return {
		types: [GET_TERMS_PAGE, GET_TERMS_PAGE_SUCCESS, GET_TERMS_PAGE_FAIL],
		promise: (client) => {
			client.modifyLanguageHeader(language);
			return client.get("/statics/support", {
				params: { type: "terms" },
			});
		},
		language,
	};
}

export function getAppJson() {
	return {
		types: [GET_APP_PAGE, GET_APP_PAGE_SUCCESS, GET_APP_PAGE_FAIL],
		promise: (client) => client.get(`/system/update`),
	};
}

export function getNewsJson({ page = 1, language, hash }) {
	return {
		types: [GET_NEWS_PAGE, GET_NEWS_PAGE_SUCCESS, GET_NEWS_PAGE_FAIL],
		promise: (client) => {
			client.modifyLanguageHeader(language);
			return client.get(`/datezone/news`, {
				params: {
					page,
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			});
		},
		hash,
	};
}

export function getSingleNewsJson({ newsId, language, hash }) {
	return {
		types: [
			GET_SINGLE_NEWS_PAGE,
			GET_SINGLE_NEWS_PAGE_SUCCESS,
			GET_SINGLE_NEWS_PAGE_FAIL,
		],
		promise: (client) => {
			client.modifyLanguageHeader(language);
			return client.get(`/datezone/news/single`, {
				params: {
					id: newsId,
				},
			});
		},
		language,
		hash,
	};
}

export function getHomePage({ language, searchSex = null, media = "any" }) {
	return {
		types: [GET_HOME_PAGE, GET_HOME_PAGE_SUCCESS, GET_HOME_PAGE_FAIL],
		promise: (client) =>
			client.get(`/merge/main`, {
				params: {
					sex: searchSex || "any",
					media,
				},
				headers: {
					Accept: "application/api.1.3+json",
				},
			}),
		language,
	};
}

export function getLanding({ language }) {
	return {
		types: [GET_LANDING, GET_LANDING_SUCCESS, GET_LANDING_FAIL],
		promise: (client) =>
			language === "pl"
				? client.post(
						"/merge/bulk/unauthorized",
						JSON.stringify([
							{
								key: "images",
								url: `datezone/main-photo`,
								args: {
									limit: 12,
									type: "square",
								},
							},
							{
								key: "seo",
								url: `datezone/main-seo-location`,
								args: {
									limit: 12,
								},
							},
							{
								key: "news",
								url: `/datezone/news`,
								args: {
									page: 1,
								},
								version: "1.1",
							},
						])
				  )
				: client.get(`/datezone/main-photo`),
		language,
	};
}

export function getLocationSeo() {
	return {
		types: [
			GET_LOCATION_SEO,
			GET_LOCATION_SEO_SUCCESS,
			GET_LOCATION_SEO_FAIL,
		],
		promise: (client) =>
			client.get(`/datezone/seo/locations`, {
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

export function getPreferencesSeo() {
	return {
		types: [
			GET_PREFERENCES_SEO,
			GET_PREFERENCES_SEO_SUCCESS,
			GET_PREFERENCES_SEO_FAIL,
		],
		promise: (client) =>
			client.get(`/datezone/seo/preference`, {
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
	};
}

export function getPosts(
	{
		page = 1,
		sex = null,
		year = null,
		month = null,
		day = null,
		city = "any",
		region = "any",
		country = "all",
		distance = 50,
	},
	isAuth,
	fromServer = false,
	infinityScroll = false,
	locationMd5Hash = ""
) {
	return {
		types: [GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL],
		promise: (client) =>
			client.get(`/post/all${isAuth ? "" : "/unauthorized"}`, {
				params: {
					page,
					sex,
					year,
					month,
					day,
					city,
					region,
					country,
					distance,
				},
			}),
		fromServer,
		infinityScroll,
		locationMd5Hash,
	};
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// ** Third Party Components
import Color from "color-js";
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";
import { StyledLoadingContainer } from "./LoadingContainer.styled";

// ** Custom Styles
import { resolveProps, marginable } from "styles/styledComponentsMixins";

// #####################################################

export const StyledBtn = styled(
	({
		big,
		active,
		block,
		color,
		colorShade,
		outline,
		size,
		isLoading,
		loadingPrepend,
		textLeft,
		open,
		noMargin,
		action,
		rounded,
		noBackgroundColor,
		onlyBorder,
		isActionButton,
		noBorder,
		addBoxShadow,
		presentationTemplate,
		...props
	}) => <Button {...resolveProps(props, marginable)} />
)(
	({
		active,
		theme,
		disabled,
		fakeDisabled,
		block,
		color,
		colorShade,
		outline,
		size,
		isLoading,
		loadingPrepend,
		textLeft,
		noMargin,
		noBackgroundColor,
		onlyBorder,
		isActionButton,
		noBorder,
		addBoxShadow,
		presentationTemplate,
	}) => {
		const buttonSize = theme.buttons[size];

		let isDarkMode = theme.isDarkMode;

		let mainColor = Color(theme.colors[color]);
		if (colorShade !== false) {
			if (colorShade < 0) {
				mainColor = Color(
					theme.utils.darken(mainColor, colorShade * -1)
				);
			} else {
				mainColor = Color(theme.utils.lighten(mainColor, colorShade));
			}
		}

		const isDark = theme.utils.isDark(mainColor);

		const bgColor = mainColor;

		const textColorLightMode =
			isDark && !outline ? theme.colors.white : theme.colors.black;
		const textColorDarkMode =
			!isDark && !outline ? theme.colors.white : theme.colors.black;
		const buttonTextColor =
			isLoading && !loadingPrepend
				? "transparent"
				: isDarkMode
				? textColorDarkMode
				: textColorLightMode;

		// #####################################################

		return css`
			font-family: Arial, Helvetica, sans-serif;

			${block &&
			css`
				width: 100%;
			`}

			button[disabled] {
				cursor: no-drop;
			}

			cursor: ${disabled ? `no-drop` : `pointer`};
			position: relative;
			display: ${block ? `block` : `inline-block`};

			white-space: normal;
			text-align: ${textLeft ? "left" : "center"};
			color: ${buttonTextColor};
			border-radius: ${({
				theme: { borderRadius },
				borderradiusconfig: {
					leftTop,
					rightTop,
					rightBottom,
					leftBottom,
				},
				borderRadiusValue,
			}) => {
				return `${
					leftTop
						? borderRadiusValue
							? borderRadiusValue
							: borderRadius.element
						: 0
				} ${
					rightTop
						? borderRadiusValue
							? borderRadiusValue
							: borderRadius.element
						: 0
				} ${
					rightBottom
						? borderRadiusValue
							? borderRadiusValue
							: borderRadius.element
						: 0
				} ${
					leftBottom
						? borderRadiusValue
							? borderRadiusValue
							: borderRadius.element
						: 0
				}`;
			}};

			font-size: ${buttonSize.fontSize}rem;
			line-height: ${buttonSize.lineHeigth}rem;

			padding: 0 1rem;
			height: 35px;

			${({ iconOnly }) =>
				iconOnly &&
				css`
					/* border: 0; */
					height: unset;
					padding: 0.4rem 0.75rem 0.4rem;
				`}

			${({ noBorder }) =>
				noBorder &&
				css`
					border: 0;
				`}

			${({ userpanelSelectorBtn }) =>
				userpanelSelectorBtn &&
				css`
					padding: 0 1rem;
					height: 33.5px !important;
				`}

			${({ register }) =>
				register &&
				css`
					height: unset;
					padding: 0.45rem 0.5rem;
					font-size: 1.1rem;
					box-shadow: ${({ theme }) =>
						theme.utils.createBoxShadow(["buttonWithText"])};
				`}

			${({ big }) =>
				big &&
				css`
					height: unset;
					padding: 0.8rem 0;
					font-size: 1.25rem;
				`}

			${marginable.css}

			${!outline &&
			css`
				background-color: ${isDarkMode && isDark
					? theme.colors.buttonsDefaultGrey
					: bgColor};
			`}


			${onlyBorder &&
			css`
				background-color: transparent;
				border: 1px solid ${theme.colors.buttonsDefaultGrey};
			`}

			${noBackgroundColor &&
			css`
				background-color: transparent;
			`}

			${({ isActionButton }) =>
				isActionButton &&
				css`
					padding: ${isActionButton};
				`}


			&:not(:disabled) {
				${!outline &&
				css`
					&:hover {
						background-color: ${isDarkMode
							? theme.utils.darken(theme.colors.grey, 4.5)
							: isDark
							? theme.utils.lighten(bgColor)
							: theme.utils.darken(bgColor, null)};
					}
					${active
						? css`
								z-index: 1;
								background-color: ${isDarkMode
									? theme.colors.buttonsDefaultGrey
									: theme.utils.darken(bgColor, 1)};
								&:hover {
									background-color: ${isDarkMode
										? theme.utils.lighten(
												theme.colors.buttonsDefaultGrey,
												0.5
										  )
										: isDark
										? theme.utils.lighten(bgColor, 1)
										: theme.utils.darken(bgColor, 1.5)};
								}
								&:active {
									background-color: ${isDark
										? isDarkMode
											? theme.utils.lighten(
													theme.colors
														.buttonsDefaultGrey,
													0.75
											  )
											: theme.utils.darken(bgColor, 1.5)
										: theme.utils.darken(bgColor, 1.5)};
								}
						  `
						: css`
								z-index: 2;
								&:hover {
									background-color: ${isDark
										? isDarkMode
											? theme.utils.lighten(
													theme.colors
														.buttonsDefaultGrey,
													0.5
											  )
											: theme.utils.darken(bgColor, 1)
										: theme.utils.darken(
												bgColor,
												color === "white" && !isDarkMode
													? 0.5
													: 1
										  )};
								}
								&:active {
									background-color: ${isDark
										? isDarkMode
											? theme.utils.lighten(
													theme.colors
														.buttonsDefaultGrey,
													0.75
											  )
											: theme.utils.darken(bgColor, 1.5)
										: theme.utils.darken(bgColor, 1.5)};
								}
						  `}
				`}

				${noBackgroundColor &&
				css`
					${active
						? css`
								z-index: 1;
								background-color: ${theme.colors
									.buttonsDefaultGrey};

								&:hover,
								&:active {
									background-color: ${theme.isDarkMode
										? theme.utils.lighten(
												theme.colors.buttonsDefaultGrey,
												0.5
										  )
										: theme.utils.darken(
												theme.colors.buttonsDefaultGrey,
												0.5
										  )};
								}
						  `
						: css`
								z-index: 2;
								&:hover,
								&:active {
									background-color: ${theme.colors
										.buttonsDefaultGrey};
								}
						  `}
				`}


				${outline &&
				css`
					&:hover {
						background-color: rgba(0, 0, 0, 0.025);
					}
					${active
						? css`
								z-index: 1;
								background-color: rgba(0, 0, 0, 0.075);
								&:hover {
									background-color: rgba(0, 0, 0, 0.075);
								}
								&:active {
									background-color: rgba(0, 0, 0, 0.1);
								}
						  `
						: css`
								z-index: 2;
								&:active {
									background-color: rgba(0, 0, 0, 0.1);
								}
						  `}
				`}
				${isActionButton &&
				css`
					background-color: transparent;
				`}
			}

			${({ presentationTemplate }) =>
				presentationTemplate &&
				css`
					&:disabled {
						cursor: not-allowed;
					}
				`}

			&:disabled {
				opacity: ${isDarkMode ? "0.65" : "0.75"};
			}

			${({ fakeDisabled }) =>
				fakeDisabled &&
				css`
					opacity: ${isDarkMode ? "0.65" : "0.75"};
				`}

			${StyledLoadingContainer} {
				svg {
					color: ${isDarkMode
						? textColorDarkMode
						: textColorLightMode};
				}
			}

			display: flex;
			align-items: center;

			justify-content: center;

			${({ rounded }) =>
				rounded &&
				css`
					border-radius: 50%;
				`}

			${({ theme, outline }) =>
				outline &&
				css`
					border: 1px solid
						${isDark
							? theme.utils.lighten(bgColor, 2)
							: theme.utils.darken(bgColor, 1.5)};
				`}


			${({ addBoxShadow }) =>
				addBoxShadow &&
				css`
					box-shadow: ${theme.utils.createBoxShadow([
						"buttonWithText",
					])};
				`}

			${({ borderGreyOnActive }) =>
				borderGreyOnActive &&
				css`
					height: unset;
					font-weight: bold;
					background-color: transparent;
					box-shadow: ${theme.utils.createBoxShadow(["minimal"])};

					&:hover,
					&:active {
						border-style: solid;
						border-width: 1px;
						border-color: ${({ theme: { utils, colors } }) =>
							isDarkMode
								? colors.inputBorderHover
								: utils.darken(colors.white, 6)};
						background-color: transparent !important;

						box-shadow: ${({ theme }) =>
							theme.utils.createBoxShadow([
								"0 1px 1px rgb(0 0 0 / 8%)",
								"panel",
							])};
					}

					${active &&
					css`
						border-style: solid;
						border-width: 1px;
						border-color: ${({ theme: { utils, colors } }) =>
							isDarkMode
								? colors.inputBorderHover
								: utils.darken(colors.white, 6)};
						background-color: transparent !important;

						box-shadow: ${({ theme }) =>
							theme.utils.createBoxShadow([
								"0 1px 1px rgb(0 0 0 / 8%)",
								"panel",
							])};
					`}
				`}
		`;
	}
);

// #####################################################

/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import defaults from "../defaults";

import { vendor } from "store/vendor";

const GET_MERGE = "events/GET_MERGE";
const GET_MERGE_SUCCESS = "events/GET_MERGE_SUCCESS";
const GET_MERGE_FAIL = "events/GET_MERGE_FAIL";

const RESET_EVENT_ERROR = "groups/RESET_EVENT_ERROR";

const GET_EVENT_MEMBERS = "events/GET_EVENT_MEMBERS";
const GET_EVENT_MEMBERS_SUCCESS = "events/GET_EVENT_MEMBERS_SUCCESS";
const GET_EVENT_MEMBERS_FAIL = "events/GET_EVENT_MEMBERS_FAIL";

const GET_EVENT_ADMINS = "events/GET_EVENT_ADMINS";
const GET_EVENT_ADMINS_SUCCESS = "events/GET_EVENT_ADMINS_SUCCESS";
const GET_EVENT_ADMINS_FAIL = "events/GET_EVENT_ADMINS_FAIL";

const GET_EVENT_POSTS = "events/GET_EVENT_POSTS";
const GET_EVENT_POSTS_SUCCESS = "events/GET_EVENT_POSTS_SUCCESS";
const GET_EVENT_POSTS_FAIL = "events/GET_EVENT_POSTS_FAIL";

const JOIN_EVENT = "events/JOIN_EVENT_ID";
const JOIN_EVENT_SUCCESS = "events/JOIN_EVENT_SUCCESS";
const JOIN_EVENT_FAIL = "events/JOIN_EVENT_FAIL";

const LEAVE_EVENT = "events/LEAVE_EVENT_ID";
const LEAVE_EVENT_SUCCESS = "events/LEAVE_EVENT_SUCCESS";
const LEAVE_EVENT_FAIL = "events/LEAVE_EVENT_FAIL";

const DELETE_REGISTRATION = "events/DELETE_REGISTRATION";
const DELETE_REGISTRATION_SUCCESS = "events/DELETE_REGISTRATION_SUCCESS";
const DELETE_REGISTRATION_FAIL = "events/DELETE_REGISTRATION_FAIL";

const ADD_POST = "events/ADD_POST";
const ADD_POST_SUCCESS = "events/ADD_POST_SUCCESS";
const ADD_POST_FAIL = "events/ADD_POST_FAIL";

const GET_POST_COMMENTS = "events/GET_POST_COMMENTS";
const GET_POST_COMMENTS_SUCCESS = "events/GET_POST_COMMENTS_SUCCESS";
const GET_POST_COMMENTS_FAIL = "events/GET_POST_COMMENTS_FAIL";

const DELETE_POST = "events/DELETE_POST";
const DELETE_POST_SUCCESS = "events/DELETE_POST_SUCCESS";
const DELETE_POST_FAIL = "events/DELETE_POST_FAIL";

const DELETE_COMMENT = "events/DELETE_COMMENT";
const DELETE_COMMENT_SUCCESS = "events/DELETE_COMMENT_SUCCESS";
const DELETE_COMMENT_FAIL = "events/DELETE_COMMENT_FAIL";

const ADD_COMMENT = "events/ADD_COMMENT";
const ADD_COMMENT_SUCCESS = "events/ADD_COMMENT_SUCCESS";
const ADD_COMMENT_FAIL = "events/ADD_COMMENT_FAIL";

const EDIT_POST = "events/EDIT_POST";
const EDIT_POST_SUCCESS = "events/EDIT_POST_SUCCESS";
const EDIT_POST_FAIL = "events/EDIT_POST_FAIL";

const EDIT_COMMENT = "events/EDIT_COMMENT";
const EDIT_COMMENT_SUCCESS = "events/EDIT_COMMENT_SUCCESS";
const EDIT_COMMENT_FAIL = "events/EDIT_COMMENT_FAIL";

const CREATE_EVENT = "events/CREATE_EVENT";
const CREATE_EVENT_SUCCESS = "events/CREATE_EVENT_SUCCESS";
const CREATE_EVENT_FAIL = "events/CREATE_EVENT_FAIL";

const EVENT_AVATAR_UPLOAD_PROGRESS = "events/EVENT_AVATAR_UPLOAD_PROGRESS";

const SET_USER_LIMIT_IN_EVENTS = "events/SET_USER_LIMIT_IN_EVENTS";

const initialState = defaults.events;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case GET_MERGE:
			if (action.isFirst)
				return {
					...state,
					event: {
						...state.event,
						info: {
							...initialState.event.info,
							loading: true,
							id: null,
							error: false,
						},
						posts: {
							...initialState.event.posts,
							loading: true,
							error: false,
						},
						admins: {
							...initialState.event.admins,
							loading: true,
							error: false,
						},
					},
				};
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						loading: true,
						error: false,
						id: null,
					},
					posts: {
						...state.event.posts,
						loading: true,
						error: false,
					},
					admins: {
						...state.event.admins,
						loading: true,
						error: false,
					},
				},
			};
		case GET_MERGE_SUCCESS:
			const {
				event: { response: event },
				admins: { response: admins },
				posts: { response: posts },
			} = action.result.data;
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						loading: false,
						loaded: true,
						error: false,
						id: event.id,
						name: event.name,
						avatar: event.avatar,
						description: {
							plain: event.description_raw,
							parsed: event.description,
							cut: event.description_cut,
						},
						created: event.date_create,
						lastPost: event.last_post_data,
						eventDate: event.event_date,
						eventTime: event.event_time,
						country: event.country,
						region: event.region,
						city: event.city,
						eventMembers: event.participants,
						status: event.your_status,
						eventPosts: event.posts,
						canJoin: event.can_send_join,
						canSendJoin: event.can_send_join,
						canLeave: event.can_leave,
						limit: event.limit,
						canPost: event.can_post,
						canPostWithoutLimit: event?.can_post_without_limit,
						canLike: event.can_like,
						automaticAdd: event.automatic_add,
						sexInvite: event.sex_invite || [],
						sexPost: event.sex_post || [],
					},
					posts: {
						...state.event.posts,
						loading: false,
						loaded: true,
						currentPage: posts?.pagination?.currentpage || null,
						totalPages: posts?.pagination?.totalpages || null,
						data: posts?.posts || [],
						error: posts?.error || null,
					},
					admins: {
						...state.event.admins,
						loading: false,
						loaded: true,
						error: false,
						data: admins,
					},
					locationMd5Hash: action.locationMd5Hash,
				},
			};
		case GET_MERGE_FAIL:
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						id: null,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
					posts: {
						...state.event.posts,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
					admins: {
						...state.event.admins,
						error: true,
						loading: false,
						loaded: true,
						errorType: action.error,
					},
				},
			};
		case RESET_EVENT_ERROR:
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						error: false,
					},
				},
			};
		case GET_EVENT_MEMBERS:
			return {
				...state,
				event: {
					...state.event,
					members_list: {
						...state.event.members_list,
						loading: true,
					},
				},
			};
		case GET_EVENT_MEMBERS_SUCCESS:
			if (action.result.data === null) {
				return {
					...state,
					event: {
						...state.event,
						members_list: {
							...initialState.event.members_list,
							loading: false,
							loaded: true,
						},
					},
				};
			}

			const newDataEvent = action.infinityScroll
				? [
						...state.event.members_list.data,
						...(action.result.data?.users ?? []),
				  ]
				: action.result.data?.users ?? [];

			return {
				...state,
				event: {
					...state.event,
					members_list: {
						...state.event.members_list,
						loading: false,
						loaded: true,
						data: action.infinityScroll
							? newDataEvent.filter((val, id) => {
									return (
										id ===
										newDataEvent.findIndex(
											(el) => el.id === val.id
										)
									);
							  })
							: newDataEvent,
						pagination: {
							totalPages:
								action.result.data.pagination.totalpages,
							currentPage:
								action.result.data.pagination.currentpage,
						},
					},
				},
			};
		case GET_EVENT_MEMBERS_FAIL:
			return {
				...state,
				event: {
					...state.event,
					members_list: {
						...state.event.members_list,
						loading: false,
						loaded: true,
					},
				},
			};
		case GET_EVENT_ADMINS:
			return {
				...state,
				event: {
					...state.event,
					admins: {
						...state.event.admins,
						loading: true,
					},
				},
			};
		case GET_EVENT_ADMINS_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					admins: {
						...state.event.admins,
						loading: false,
						loaded: true,
						data: action.result.data ?? [],
					},
				},
			};
		case GET_EVENT_ADMINS_FAIL:
			return {
				...state,
				event: {
					...state.event,
					admins: {
						...state.event.admins,
						loading: false,
						loaded: true,
					},
				},
			};
		case GET_EVENT_POSTS:
			if (action.first)
				return {
					...state,
					event: {
						...state.event,
						posts: {
							...initialState.event.posts,
							loading: true,
						},
					},
				};
			return {
				...state,
				event: {
					...state.event,
					posts: {
						...state.event.posts,
						loading: true,
					},
				},
			};
		case GET_EVENT_POSTS_SUCCESS:
			if (action.result.data === null) {
				return {
					...state,
					event: {
						...state.event,
						posts: {
							...initialState.event.posts,
							loading: false,
							loaded: true,
						},
					},
				};
			}
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						eventPosts: action.first
							? state.event.info.eventPosts
							: state.event.info.eventPosts + 1,
					},
					posts: {
						...state.event.posts,
						loading: false,
						loaded: true,
						currentPage: action.result.data.pagination.currentpage,
						totalPages: action.result.data.pagination.totalpages,
						data: action.result.data.posts,
					},
				},
			};
		case JOIN_EVENT:
			return {
				...state,
				event: {
					...state.event,
					joinEvent: {
						...state.event.joinEvent,
						loading: true,
					},
				},
			};
		case JOIN_EVENT_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					joinEvent: {
						...state.event.joinEvent,
						loading: false,
						success: true,
					},
				},
			};
		case JOIN_EVENT_FAIL:
			return {
				...state,
				event: {
					...state.event,
					joinEvent: {
						...state.event.joinEvent,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case LEAVE_EVENT:
			return {
				...state,
				event: {
					...state.event,
					leaveEvent: {
						...state.event.leaveEvent,
						loading: true,
					},
				},
			};
		case LEAVE_EVENT_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					leaveEvent: {
						...state.event.leaveEvent,
						loading: false,
						success: true,
					},
				},
			};
		case LEAVE_EVENT_FAIL:
			return {
				...state,
				event: {
					...state.event,
					leaveEvent: {
						...state.event.leaveEvent,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case DELETE_REGISTRATION:
			return {
				...state,
				event: {
					...state.event,
					deleteRegistration: {
						...state.event.deleteRegistration,
						loading: true,
					},
				},
			};
		case DELETE_REGISTRATION_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					deleteRegistration: {
						...state.event.deleteRegistration,
						loading: false,
						success: true,
					},
				},
			};
		case DELETE_REGISTRATION_FAIL:
			return {
				...state,
				event: {
					...state.event,
					deleteRegistration: {
						...state.event.deleteRegistration,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case ADD_POST:
			return {
				...state,
				event: {
					...state.event,
					addPost: {
						...state.event.addPost,
						loading: true,
					},
				},
			};
		case ADD_POST_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					addPost: {
						...state.event.addPost,
						loading: false,
						success: true,
					},
				},
			};
		case ADD_POST_FAIL:
			return {
				...state,
				event: {
					...state.event,
					addPost: {
						...state.event.addPost,
						loading: false,
						success: false,
						error: true,
					},
				},
			};
		case GET_POST_COMMENTS:
			return {
				...state,
			};
		case GET_POST_COMMENTS_SUCCESS: {
			const data = state.event.posts.data.map((post) => {
				if (post.id === action.post_id) {
					post.count_comments = action.result?.data?.total;

					if (action.more) {
						const commentsIndex = new Map();
						post.comments.map((comment, index) =>
							commentsIndex.set(comment.id, index)
						);

						const comments = post.comments;

						for (const comment of action.result?.data?.comments ??
							[]) {
							const index = commentsIndex.get(comment.id);

							if (index == null) {
								comments.push(comment);
								continue;
							}

							comments[index] = comment;
						}

						return {
							...post,
							comments,
						};
					}

					return {
						...post,
						comments: action.result?.data?.comments ?? [],
					};
				}
				return post;
			});

			return {
				...state,
				event: {
					...state.event,
					posts: {
						...state.event.posts,
						data,
					},
				},
			};
		}
		case GET_POST_COMMENTS_FAIL:
			return {
				...state,
			};
		case DELETE_POST:
			return state;
		case DELETE_POST_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						eventPosts: state.event.info.eventPosts - 1,
					},
				},
			};
		case DELETE_POST_FAIL:
			return state;
		case DELETE_COMMENT:
			return state;
		case DELETE_COMMENT_SUCCESS:
			return state;
		case DELETE_COMMENT_FAIL:
			return state;
		case ADD_COMMENT:
			return {
				...state,
				event: {
					...state.event,
					addComment: {
						...initialState.event.addComment,
						loading: true,
					},
				},
			};
		case ADD_COMMENT_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					addComment: {
						...state.event.addComment,
						loading: false,
						success: true,
						error: false,
					},
				},
			};
		case ADD_COMMENT_FAIL:
			return {
				...state,
				event: {
					...state.event,
					addComment: {
						...state.event.addComment,
						loading: false,
						error: true,
					},
				},
			};
		case EDIT_POST:
			return {
				...state,
				event: {
					...state.event,
					editPost: {
						...initialState.event.editPost,
						loading: true,
						value: action.data.text,
					},
				},
			};
		case EDIT_POST_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					editPost: {
						...state.event.editPost,
						loading: false,
						success: true,
					},
				},
			};
		case EDIT_POST_FAIL:
			return {
				...state,
				event: {
					...state.event,
					editPost: {
						...initialState.event.editPost,
						loading: false,
						error: true,
					},
				},
			};
		case EDIT_COMMENT:
			return {
				...state,
				event: {
					...state.event,
					editComment: {
						...initialState.event.editComment,
						loading: true,
						value: action.data.text,
					},
				},
			};
		case EDIT_COMMENT_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					editcomment: {
						...state.event.editComment,
						loading: false,
						success: true,
					},
				},
			};
		case EDIT_COMMENT_FAIL:
			return {
				...state,
				event: {
					...state.event,
					editComment: {
						...initialState.event.editComment,
						loading: false,
						error: true,
					},
				},
			};
		case CREATE_EVENT:
			return {
				...state,
				event: {
					...state.event,
					avatarUploadProgress: 0,
				},
			};
		case EVENT_AVATAR_UPLOAD_PROGRESS:
			return {
				...state,
				event: {
					...state.event,
					avatarUploadProgress: action.avatarUploadProgress,
				},
			};
		case SET_USER_LIMIT_IN_EVENTS:
			return {
				...state,
				event: {
					...state.event,
					info: {
						...state.event.info,
						limit: action.payload,
					},
				},
			};
		default:
			return state;
	}
}

/**
 * MERGE
 *
 * @param {*} id
 * @param {*} isAuth
 * @param {*} isFirst
 * @returns
 */
export function getEventMergeById(id, isAuth, isFirst, locationMd5Hash = "") {
	return {
		types: [GET_MERGE, GET_MERGE_SUCCESS, GET_MERGE_FAIL],
		promise: (client) =>
			client.post(
				isAuth ? "/merge/bulk" : "/merge/bulk/unauthorized",
				JSON.stringify([
					{
						key: "event",
						url: `event${isAuth ? "" : "/unauthorized"}`,
						args: {
							id,
						},
					},
					{
						key: "admins",
						url: "event-user/admins",
						args: {
							id,
						},
					},
					{
						key: "posts",
						url: `event-content${isAuth ? "" : "/unauthorized"}`,
						args: {
							id,
							page: 1,
							limit: 5,
							comment: "true",
						},
					},
				])
			),
		isFirst,
		locationMd5Hash,
	};
}

export function resetEventError() {
	return {
		type: RESET_EVENT_ERROR,
	};
}

/**
 * Pobiera członków imprezy.
 * @api GET getEventUser
 * @param {Number} id identyfikator imprezy
 * @param {Number} page numer strony
 * @param {String} sex szukana płeć
 */
export function getEventMembersById(id, page, sex, infinityScroll = false) {
	return {
		types: [
			GET_EVENT_MEMBERS,
			GET_EVENT_MEMBERS_SUCCESS,
			GET_EVENT_MEMBERS_FAIL,
		],
		promise: (client) =>
			client.get(`/event-user`, {
				params: {
					id,
					page,
					sex,
					limit: 30,
				},
			}),
		infinityScroll,
	};
}

/**
 * Pobiera posty w imprezieie.
 * @api GET getEventPosts
 * @param {Number} id identyfikator imprezy
 * @param {Number} page numer strony
 * @param {Number} limit limit postów na stronie
 */

export function getEventPosts({
	id,
	page,
	limit = 10,
	sex = "any",
	isAuth = true,
	first,
}) {
	return {
		types: [GET_EVENT_POSTS, GET_EVENT_POSTS_SUCCESS, GET_EVENT_POSTS_FAIL],
		promise: (client) =>
			client.get(`/event-content${isAuth ? "" : "/unauthorized"}`, {
				params: {
					id,
					page,
					sex,
					comment: true,
					limit,
				},
			}),
		first,
	};
}

/**
 * Dołącza użytkownika do imprezy
 * @api POST joinToEvent
 * @param {Number} id identyfikator imprezy
 */

export function joinEvent(id) {
	return {
		types: [JOIN_EVENT, JOIN_EVENT_SUCCESS, JOIN_EVENT_FAIL],
		promise: (client) => client.post(`/event-user?id=${id}`),
	};
}

/**
 * Usuwa użytkownika z imprezy
 * @api DELETE leaveEvent
 * @param {Number} id identyfikator imprezy
 */

export function leaveEvent(id) {
	return {
		types: [LEAVE_EVENT, LEAVE_EVENT_SUCCESS, LEAVE_EVENT_FAIL],
		promise: (client) => client.delete(`/event-user/leave?id=${id}`),
	};
}

/**
 * Usuwa użytkownika z listy oczekujących do przyjęcia do imprezy
 * @api DELETE deleteEventsSendInvite
 * @param {Number} id identyfikator imprezy
 */

export function deleteRegistration(id) {
	return {
		types: [
			DELETE_REGISTRATION,
			DELETE_REGISTRATION_SUCCESS,
			DELETE_REGISTRATION_FAIL,
		],
		promise: (client) =>
			client.delete(`/event/send-invite`, {
				params: {
					id,
				},
			}),
	};
}

/**
 * Dodaje post do imprezy
 * @api POST addEventPost
 */

export function addPost(event_id, text) {
	const data = { event_id, text };
	return {
		types: [ADD_POST, ADD_POST_SUCCESS, ADD_POST_FAIL],
		promise: (client) => client.post("/event-content", data),
	};
}

/**
 * Pobiera komentzrae do posta
 * @api GET getEventPostComments
 * @param {Number} event_id identyfikator imprezy
 * @param {Number} post_id identyfikator postu
 * @param {Number} page numer strony
 * @param {Number} limit limit postów na stronie
 */

export function getPostComments({
	eventId,
	postId,
	page = 1,
	limit = 5,
	more = false,
}) {
	return {
		types: [
			GET_POST_COMMENTS,
			GET_POST_COMMENTS_SUCCESS,
			GET_POST_COMMENTS_FAIL,
		],
		promise: (client) =>
			client.get(`/event-content/comment`, {
				params: {
					id: eventId,
					postId,
					page,
					limit,
				},
			}),
		post_id: postId,
		more,
	};
}

/**
 * Usuwa post
 * @api DELETE deleteEventPost
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} postId identyfikator postu
 */

export function deletePost(eventId, postId) {
	return {
		types: [DELETE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAIL],
		promise: (client) =>
			client.delete(`/event-content`, {
				params: {
					id: eventId,
					postId,
				},
			}),
		post_id: postId,
	};
}

/**
 * Usuwa komentarz
 * @api DELETE deleteEventPostComment
 * @param {Number} eventId identyfikator imprezy
 * @param {Number} postId identyfikator postu
 * @param {Number} commentId identyfikator komentarza
 */

export function deleteComment(eventId, postId, commentId) {
	return {
		types: [DELETE_COMMENT, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAIL],
		promise: (client) =>
			client.delete(`/event-content/comment`, {
				params: {
					id: eventId,
					postId,
					commentId,
				},
				headers: {
					Accept: "application/api.1.1+json",
				},
			}),
		post_id: postId,
		comment_id: commentId,
	};
}

/**
 * Dodaje komentarz
 * @api DELETE deleteEventPostComment
 * @param {Object} data id imprezy/id postu/treść komentarza
 */

export function addComment(data) {
	return {
		types: [ADD_COMMENT, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAIL],
		promise: (client) => client.post(`/event-content/comment`, data),
	};
}

/**
 * Edytuje post w imrezie
 * @api POST editEventPost
 * @param {Object} data id imprezy/id postu/nowa treść postu
 */

export function editPost(data) {
	return {
		types: [EDIT_POST, EDIT_POST_SUCCESS, EDIT_POST_FAIL],
		promise: (client) => client.put("/event-content", data),
		data,
	};
}

/**
 * Dodaje post do imprezy
 * @api POST editEventcomment
 * @param {Object} data id imprezy/id postu/id komentarza/nowa treść komentarza
 */

export function editComment(data) {
	return {
		types: [EDIT_COMMENT, EDIT_COMMENT_SUCCESS, EDIT_COMMENT_FAIL],
		promise: (client) => client.put("/event-content/comment", data),
		data,
	};
}

/**
 * Tworzy nową impreze
 * @api POST createNewEvent
 * @param {Object} data
 */

export function createEvent(data, avatar) {
	const formData = new FormData();

	formData.append("data", JSON.stringify(data));
	if (avatar) formData.append("avatar", avatar);

	return {
		types: [CREATE_EVENT, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAIL],
		promise: (client) =>
			client
				.get("/system/token")
				.then(({ data: { token: _uploadToken } }) => {
					formData.append("token", _uploadToken);
					return client.post("/event", formData, {
						onUploadProgress: (e) => {
							vendor.store.dispatch(
								setEventAvatarUploadProgress(
									Math.round((e.loaded * 100) / e.total)
								)
							);
						},
					});
				}),
	};
}

export function setEventAvatarUploadProgress(progress) {
	return {
		type: EVENT_AVATAR_UPLOAD_PROGRESS,
		avatarUploadProgress: progress,
	};
}

export const setUserLimitInEvents = (limit) => ({
	type: SET_USER_LIMIT_IN_EVENTS,
	payload: limit,
});

export const initialQuery = {
	sort: "popular",
	page: 1,
	country: "",
	region: "",
	city: "",
	distance: "",
	dateStart: "",
	dateEnd: "",
	name: "",
};
